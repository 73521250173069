import Warehouse from 'components/Warehouse';
import Wrapper from 'components/Wrapper';

function WarehousePage() {
  return (
    <Wrapper>
      <Warehouse />
    </Wrapper>
  );
}

export default WarehousePage;
