import JobDetails from 'components/JobDetails';
import Wrapper from 'components/Wrapper';

function JobDetailPage() {
  return (
    <Wrapper>
      <JobDetails />
    </Wrapper>
  );
}

export default JobDetailPage;
