// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

import { Button, Modal } from "react-bootstrap";
// reactstrap components
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AxiosContext from '../store/axios-context';
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import { Row, Col, } from "reactstrap";
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import ReactExport from "react-export-excel";
import moment from 'moment';
import { CSVLink } from "react-csv";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'AWB Number/Local Order',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'rack',
    numeric: false,
    disablePadding: false,
    label: 'Rack',
  },
  {
    id: 'total_package',
    numeric: false,
    disablePadding: false,
    label: 'Total Packages',
  },
  {
    id: 'packages_in_warehouse',
    numeric: false,
    disablePadding: false,
    label: 'Packages in Warehouse',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Warehouse() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const history = useHistory();

  const [status, setStatus] = useState('');
  const [formError, setFormError] = useState(null);
  const [commonError, setCommonError] = useState(null);
  const [warehouses, setWarehouses] = useState([]);

  const [airwaybillsLocalorders, setAirwaybillsLocalorders] = useState(null);
  const [selectedAirwaybillLocalOrder, setSelectedAirwaybillLocalOrder] = useState(null);
  const [locs, setLocs] = useState(null);
  const [loc, setLoc] = useState(null);
  const [rack, setRack] = useState(null);
  const [racks, setRacks] = useState(null);
  const [totalPackages, setTotalPackages] = useState('');
  const [packagesInDate, setPackagesInDate] = useState('');
  const [numberOfPackagesOut, setNumberOfPackagesOut] = useState('');
  const [packagesOutDate, setPackagesOutDate] = useState('');
  const [warehouseId, setWarehouseId] = useState('');
  const [packagesOutValues, setPackagesOutValues] = useState([{ date: "", no_of_packages_out: 0 }])

  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const getWarehouses = () => {
    setLoading(true);
    axiosCtx.instance.get(`warehouse`)
      .then((response) => {
        setWarehouses(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        setLoading(false);
      });
  };

  const getAirwaybillsLocalorders = () => {
    axiosCtx.instance.get(`airwaybills-localorders`)
      .then((response) => {
        setAirwaybillsLocalorders(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getLocations = () => {
    axiosCtx.instance.get(`location`)
      .then((response) => {
        setLocs(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getRacks = () => {
    axiosCtx.instance.get(`rack`)
      .then((response) => {
        setRacks(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    getWarehouses();
    getRacks();
    getLocations();
    getAirwaybillsLocalorders();
  }, []);

  const onWarehouseEditHandler = (item) => {
    history.push({
      pathname: `warehouse-edit/${item.id}`,
      state: {
      }
    });

    // setWarehouseId(item.id);
    // setSelectedAirwaybillLocalOrder(item.airwaybill_localorder);
    // setLoc(item.location);
    // setRack(item.rack);
    // setTotalPackages(item.total_packages);
    // setNumberOfPackagesOut(item.num_packages_out);
    // setPackagesOutDate(item.packages_out_date);
    // setPackagesInDate(item.packages_in_date);
    // handleShow();
  };

  const onWarehouseDeleteHandler = (item) => {
    setDeleteId(item);
    handleShowDelete();
  };

  const deleteSubmitHandler = () => {
    setLoading(true);
    if (deleteId) {
      console.log(deleteId);
      axiosCtx.instance.delete(`warehouse/` + deleteId,)
        .then((response) => {
          setLoading(false);
          setDeleteMessage(response.data.data);
          setDeleteId('');
          handleCloseDelete();
          getWarehouses();
        })
        .catch((error) => {
          setLoading(false);
          setDeleteMessage(error.response.data.errors);
        });
    }
  };

  const [showDelete, setShowDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const handleCloseDelete = () => {
    setShowDelete(false);
    setDeleteMessage('');
    setDeleteId('');

  }
  const handleShowDelete = () => setShowDelete(true);

  const submitHandler = (event) => {
    event.preventDefault();
    setFormError(null);
    setLoading(true);

    // Call login API
    const params = {
      airway_bill_localorder: (selectedAirwaybillLocalOrder) ? selectedAirwaybillLocalOrder.order_number : '',
      location: (loc) ? loc.id : '',
      rack: (rack) ? rack.id : '',
      total_packages: totalPackages,
      num_packages_out: numberOfPackagesOut,
      packages_in_date: packagesInDate,
      packages_out_date: packagesOutDate,
      packages_out: JSON.stringify(packagesOutValues),
    };

    if (warehouseId) {
      if (numberOfPackagesOut > totalPackages) {
        var err = "Number of packages must be less than total packages";
        setCommonError(err);
      }
      else {
        axiosCtx.instance.put(`warehouse/` + warehouseId, params)
          .then((response) => {
            setLoading(false);
            getWarehouses();
            handleClose();
          })
          .catch((error) => {
            setFormError(error.response.data);
            setLoading(false);
          });
      }

    }
    else {
      axiosCtx.instance.post(`warehouse`, params)
        .then((response) => {
          setLoading(false);
          getWarehouses();
          handleClose();
        })
        .catch((error) => {
          setFormError(error.response.data);
          setLoading(false);
        });
    }

  };

  const filterSubmit = (event) => {
    event.preventDefault();
    setFormError(null);
    setLoading(true);
    const params = {
      airway_bill_localorder: (selectedAirwaybillLocalOrder) ? selectedAirwaybillLocalOrder.order_number : '',
      location: (loc) ? loc.id : '',
      rack: (rack) ? rack.id : '',
    };

    axiosCtx.instance.post(`warehouse/filter`, params)
      .then((response) => {
        setWarehouses(response.data.data);
        filterClose();
        setLoading(false);
      })
      .catch((error) => {
        setFormError(error.response.data);
        setLoading(false);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = warehouses.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();


    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setWarehouseId(null);
    setFormError(null);
    setCommonError(null);
    setShow(false);
    setSelectedAirwaybillLocalOrder(null);
    setLoc(null);
    setRack(null);
    setTotalPackages('');
    setNumberOfPackagesOut('');
    setPackagesOutDate('');
    setPackagesInDate('');
  }
  const handleShow = () => setShow(true);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => {
    setShowFilter(false);

  }

  const filterClear = () => {
    setSelectedAirwaybillLocalOrder(null);
    setLoc(null);
    setRack(null);
    getWarehouses();
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - warehouses.length) : 0;
  const [age, setAge] = React.useState('');
  const handleChange = (event) => { setAge(event.target.value); };

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#2f67c9");
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  let [excelData, setExcelData] = useState([]);
  const sortWarehouse = () => {
    let data = [...warehouses];
    data = data.map(row => (
      {
        ...row,
        airwaybill_localorder: row.airwaybill_localorder ? row.airwaybill_localorder.order_number : "",
        location: row.location ? row.location.name : "",
        rack: row.rack ? row.rack.name : "",
        total_packages: row.total_packages ? parseFloat(row.total_packages) : 0,
        packages_in_date: row.packages_in_date ? moment(row.packages_in_date).format("YYYY-MM-DD") : '',
        packages_in_warehouse: row.packages_in_warehouse ? parseFloat(row.packages_in_warehouse) : 0,
      }
    ));
    setExcelData(data);
  }

  const headers = [
    { label: "AWB/Local Order Number", key: "airwaybill_localorder" },
    { label: "Location", key: "location" },
    { label: "Rack", key: "rack" },
    { label: "Total Packages", key: "total_packages" },
    { label: "Packages In Date", key: 'packages_in_date' },
    { label: "Packages In Warehouse", key: 'packages_in_warehouse' },
  ];

  return (
    <div className="content">
      {loading && loading == true ?
        <div className="custom-loader">
          <FadeLoader color={color} loading={loading} css={override} size={150} />
        </div>
        : ''
      }
      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Warehouse List</h4>

        <div className="form-row align-items-center justify-content-end">
          <div className="col-auto my-1 position-relative">
            <button className="btn btn-simple" onClick={() => setShowFilter((currentShow) => !currentShow)} >
              Filter
            </button>
            <CSVLink data={excelData} headers={headers} className="btn btn-simple" onClick={event => {
              sortWarehouse();
              return true;
            }}>
              Download
            </CSVLink>
            {showFilter ? (
              <div className="filter-container p-4 bg-white shadow-sm rounded">
                <Row>
                  <Col md="6" className="mb-4">
                    <FormControl variant="outlined" className="w-100">
                      <Autocomplete
                        value={selectedAirwaybillLocalOrder || null}
                        size="small"
                        disabled={(warehouseId) ? true : false}
                        onChange={(event, newValue) => {
                          setSelectedAirwaybillLocalOrder(newValue);
                        }}
                        disablePortal
                        id="party-box-demo"
                        options={airwaybillsLocalorders}
                        getOptionLabel={(option) => option.order_number}
                        renderInput={(params) => <TextField {...params} label="Airwaybill/Localorder Number" />}
                      />
                      {formError &&
                        formError.errors.have_warehouse && (
                          <p className="text-danger small pt-2">
                            {formError.errors.have_warehouse}
                          </p>
                        )}

                    </FormControl>
                  </Col>
                  <Col md="6" className="mb-4">
                    <FormControl variant="outlined" className="w-100">
                      <Autocomplete
                        value={loc || null}
                        size="small"
                        onChange={(event, newValue) => {
                          setLoc(newValue);
                        }}
                        disablePortal
                        id="party-box-demo"
                        options={locs}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Location" />}
                      />
                    </FormControl>

                  </Col>
                  <Col md="6" className="mb-4">
                    <FormControl variant="outlined" className="w-100">
                      {/* <InputLabel id="select1">Sub Type</InputLabel> */}
                      <Autocomplete
                        value={rack || null}
                        size="small"
                        onChange={(event, newValue) => {
                          setRack(newValue);
                        }}
                        disablePortal
                        id="party-box-demo"
                        options={racks}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Rack" />}
                      />


                    </FormControl>

                  </Col>
                  <Col md="6" className="mb-4">


                  </Col>
                  <Col md="6" className="mb-4">


                  </Col>



                </Row>
                <div className="text-right border-top pt-3">
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClear}
                  >
                    Clear
                  </Button>
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="my-0  "
                    variant="primary"
                    onClick={filterSubmit}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-auto my-1">
            <button className="btn btn-primary" onClick={handleShow}>
              Create new Warehouse
            </button>
          </div>
        </div>

        {/* ******************** modal start here **************** */}
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">{(warehouseId) ? 'Edit' : 'Create a new'} Warehouse </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Row>

              <Col md="6" className="my-4">
                <FormControl variant="outlined" className="w-100">
                  <Autocomplete
                    value={selectedAirwaybillLocalOrder || null}
                    size="small"
                    disabled={(warehouseId) ? true : false}
                    onChange={(event, newValue) => {
                      setSelectedAirwaybillLocalOrder(newValue);
                      setTotalPackages(newValue.pieces);
                    }}
                    disablePortal
                    id="party-box-demo"
                    options={airwaybillsLocalorders}
                    getOptionLabel={(option) => option.order_number}
                    renderInput={(params) => <TextField {...params} label="Airwaybill/Localorder Number" />}
                  />
                  {formError &&
                    formError.errors.have_warehouse && (
                      <p className="text-danger small pt-2">
                        {formError.errors.have_warehouse}
                      </p>
                    )}

                </FormControl>

              </Col>
              <Col md="6" className="my-4">
                <TextField disabled={true} size="small" className="w-100" onChange={(e) => setTotalPackages(e.target.value)} value={totalPackages} label="Total Packages" variant="outlined" />
              </Col>
              <Col md="6" className="my-4">
                <FormControl variant="outlined" className="w-100">
                  <Autocomplete
                    value={loc || null}
                    size="small"
                    onChange={(event, newValue) => {
                      setLoc(newValue);
                    }}
                    disablePortal
                    id="party-box-demo"
                    options={locs}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Location" />}
                  />


                </FormControl>
              </Col>
              <Col md="6" className="my-4">
                <FormControl variant="outlined" className="w-100">
                  {/* <InputLabel id="select1">Sub Type</InputLabel> */}
                  <Autocomplete
                    value={rack || null}
                    size="small"
                    onChange={(event, newValue) => {
                      setRack(newValue);
                    }}
                    disablePortal
                    id="party-box-demo"
                    options={racks}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Rack" />}
                  />


                </FormControl>
              </Col>

              <Col md="6" className="my-4">
                <TextField type="date" size="small" className="w-100" onChange={(e) => setPackagesInDate(e.target.value)} value={packagesInDate} label="Packages In Date" variant="outlined" InputLabelProps={{
                  shrink: true,
                }} />
              </Col>
              {(warehouseId) ?
                <>
                  <Col md="6" className="my-4">
                    <TextField size="small" className="w-100" onChange={(e) => setNumberOfPackagesOut(e.target.value)} value={numberOfPackagesOut} label="Number Of Packages out" variant="outlined" />
                    {formError &&
                      formError.errors.number_of_packages && (
                        <p className="text-danger small pt-2">
                          {formError.errors.number_of_packages}
                        </p>
                      )}
                  </Col>

                  <Col md="6" className="my-4">
                    <TextField type="date" size="small" className="w-100" onChange={(e) => setPackagesOutDate(e.target.value)} value={packagesOutDate} label="Packages Out Date" variant="outlined" InputLabelProps={{
                      shrink: true,
                    }} />
                  </Col>
                </>
                : ''}

            </Row>
            {
              commonError && (
                <p className="text-danger small pt-2">
                  {commonError}
                </p>
              )}
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={submitHandler}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ******************** delete modal start here **************** */}
        <Modal show={showDelete} onHide={handleCloseDelete} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Delete Warehouse </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
            <p>{deleteMessage}</p>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={deleteSubmitHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={warehouses.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(warehouses, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox" className="selectCheckbox">
                          {/* <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            className="selectCheckbox"
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          /> */}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none" > {row.airwaybill_localorder.order_number} </TableCell>
                        <TableCell>{(row.location) ? row.location.name : ''}</TableCell>
                        <TableCell>{(row.rack) ? row.rack.name : ''}</TableCell>
                        <TableCell>{row.total_packages}</TableCell>
                        <TableCell>{row.packages_in_warehouse}</TableCell>
                        {/* <TableCell>{row.packages_in_warehouse}  </TableCell> */}
                        {/* <TableCell>{row.package_in_date}  </TableCell> */}
                        <TableCell><button onClick={() => onWarehouseEditHandler(row)} className="btn btn-primary btn-sm font-weight-bold ">Edit</button>
                          <button onClick={() => onWarehouseDeleteHandler(row.id)} className="btn btn-danger btn-sm font-weight-bold ">Delete</button></TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={warehouses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

      </Box>
    </div>
  );
}
