import CostReport from 'components/Reports/CostReport';
import Wrapper from 'components/Wrapper';

function CostReportPage() {
  return (
    <Wrapper>
      <CostReport />
    </Wrapper>
  );
}

export default CostReportPage;
