import JobPage from "views/JobPage.js";
import JobDetailPage from "views/JobDetailPage.js";
import InvoicePage from "views/InvoicePage.js";
import LoginPage from "views/LoginPage.js";
import AirwayBillDetailPage from "views/AirwayBillDetailPage.js";
import JobReportPage from "views/JobReportPage";
import CostReportPage from "views/CostReportPage";
import InvoiceReportPage from "views/InvoiceReportPage";
import MasterDataPage from "views/MasterDataPage";
import PodPdfPage from "views/PodPdfPage";
import InvoicePdfPage from "views/InvoicePdfPage";
import InvoiceEditPage from "views/InvoiceEditPage";
import AirwayBillPdfPage from "views/AirwayBillPdfPage";
import ExitCertificatePdfPage from "views/ExitCertificatePdfPage";
import RedirectRoot from "views/RedirectRoot";
import WarehousePage from "views/WarehousePage";
import OperationalCostPage from "views/OperationalCostPage";
import WarehouseEditPage from "views/WarehouseEditPage";
import CustomerPage from "views/CustomerPage";



var routes = [
  {
    path: "/",
    name: "",
    icon: "",
    component: RedirectRoot,
    layout: "/",
  },
  {
    path: "job-list",
    name: "Job List",
    icon: "nc-icon nc-delivery-fast",
    component: JobPage,
    layout: "/",
  },
  {
    path: "customers",
    name: "Customers",
    icon: "nc-icon nc-delivery-fast",
    component: CustomerPage,
    layout: "/",
  },
  {
    path: "Invoice",
    name: "Invoice",
    icon: "nc-icon nc-paper",
    component: InvoicePage,
    layout: "/",
  },
  {
    path: "invoice-edit/:id",
    name: "Invoice",
    icon: "nc-icon nc-paper",
    component: InvoiceEditPage,
    layout: "/",
  },
  {
    path: "job-report",
    name: "Job Report",
    icon: "nc-icon nc-delivery-fast",
    component: JobReportPage,
    layout: "/",
  },
  {
    path: "cost-report",
    name: "Cost Report",
    icon: "nc-icon nc-delivery-fast",
    component: CostReportPage,
    layout: "/",
  },
  {
    path: "invoice-report",
    name: "Invoice Report",
    icon: "nc-icon nc-delivery-fast",
    component: InvoiceReportPage,
    layout: "/",
  },
  {
    path: "master-data",
    name: "Master Data",
    icon: "nc-icon nc-delivery-fast",
    component: MasterDataPage,
    layout: "/",
  },
  {
    path: "job-details",
    name: "Job Details",
    icon: "nc-icon nc-cart-simple",
    component: JobDetailPage,
    layout: "/",
    show_sidebar: false,
  },
  {
    path: "airwaybill/:id",
    name: "AirwayBill Details",
    icon: "nc-icon nc-cart-simple",
    component: AirwayBillDetailPage,
    layout: "/",
    show_sidebar: false,
  },
  {
    path: "airwaybill",
    name: "AirwayBill Details",
    icon: "nc-icon nc-cart-simple",
    component: AirwayBillDetailPage,
    layout: "/",
    show_sidebar: false,
  },
  {
    path: "invoice-pdf/:id",    
    component: InvoicePdfPage,
    layout: "/",
    display: 'none',
  },
  {
    path: "/Login",
    component: LoginPage,
    layout: "/auth",
    show_sidebar: false,
  },
  {
    path: "podpdf",    
    component: PodPdfPage,
    layout: "/",
    display: 'none',
  },
  {
    path: "airwaybill-pdf/:id",    
    component: AirwayBillPdfPage,
    layout: "/",
    display: 'none',
  },  
  {
    path: "exitcertificate-pdf/:id",    
    component: ExitCertificatePdfPage,
    layout: "/",
    display: 'none',
  },
  {
    path: "warehouse",
    name: "Job List",
    icon: "nc-icon nc-delivery-fast",
    component: WarehousePage,
    layout: "/",
  },
  {
    path: "warehouse-edit/:id",
    name: "Warehouse",
    icon: "nc-icon nc-paper",
    component: WarehouseEditPage,
    layout: "/",
  },
  {
    path: "operational-cost",
    name: "Job List",
    icon: "nc-icon nc-delivery-fast",
    component: OperationalCostPage,
    layout: "/",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-diamond",
  //   component: Icons,
  //   layout: "/",
  // }
];
export default routes;
