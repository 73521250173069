import MasterData from 'components/MasterData';
import Wrapper from 'components/Wrapper';

function MasterDataPage() {
  return (
    <Wrapper>
      <MasterData />
    </Wrapper>
  );
}

export default MasterDataPage;
