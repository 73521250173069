// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button, Modal } from "react-bootstrap";
// reactstrap components
import { Row, Col, } from "reactstrap";
import AxiosContext from '../store/axios-context';
import { useHistory } from 'react-router';
import AuthContext from '../store/auth-context';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import InputField from "./Fields/InputField";
import InputDate from "./Fields/InputDate";
import Dropdown from "./Fields/Dropdown";
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function createData(id, jobType, project, party, status, action) {
    return {
        id,
        jobType,
        project,
        party,
        status,
        action,
    };
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: true,
        label: 'Customer Name',
    },
    {
        id: 'Type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'amount',
        numeric: false,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'Due Date',
        numeric: false,
        disablePadding: false,
        label: 'Due Date',
    },
    {
        id: 'sendDate',
        numeric: false,
        disablePadding: false,
        label: 'Send Date',
    },
    {
        id: 'Status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: '',
        numeric: false,
        disablePadding: false,
        label: '',
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="font-weight-bold"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <span></span>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function Invoice() {
    const history = useHistory();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [formName, setFormName] = React.useState('Create');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [invoices, setInvoices] = useState([]);
    const axiosCtx = useContext(AxiosContext);
    const authCtx = useContext(AuthContext);
    const [formError, setFormError] = useState(null);

    const getInvoices = () => {
        axiosCtx.instance.get(`invoice`)
            .then((response) => {
                console.log(response.data.data);
                setInvoices(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    useEffect(() => {
        if (authCtx.isLoggedIn == false) {
            history.replace('/auth/Login');
        }
        getInvoices();
        getJobs();
        getParties();
        getProjects();
        getCurrency();
        getStatues();
        getJobtypes();
    }, []);

    const onPrintClickHandler = (item) => {
        history.push({
            pathname: `invoice-pdf/${item.id}`,
            state: {}
        });
    };

    const onDeleteClickHandler = (item) => {
        setDeleteInvoiceId(item.id);
        handleShowDelete();
    }

    const deleteSubmitHandler = () => {
        setLoading(true);
        if (deleteInvoiceId) {
            console.log(deleteInvoiceId);
            axiosCtx.instance.delete(`invoice/` + deleteInvoiceId,)
                .then((response) => {
                    setLoading(false);
                    setDeleteMessage(response.data.data);
                    setDeleteInvoiceId('');
                    getInvoices();
                    handleCloseDelete();
                })
                .catch((error) => {
                    setDeleteMessage(error.response.data.errors);
                    setLoading(false);
                });
        }
    };

    const [showDelete, setShowDelete] = useState(false);
    const [showProduct, setShowProduct] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [deleteInvoiceId, setDeleteInvoiceId] = useState('');
    const handleCloseDelete = () => {
        setShowDelete(false);
        setDeleteMessage('');
        setDeleteInvoiceId('');
    }
    const handleCloseProduct = () => {
        setShowProduct(false);
    }
    const handleShowDelete = () => setShowDelete(true);

    const onEditClickHandler = (item) => {
        history.push({
            pathname: `invoice-edit/${item.id}`,
            state: {
            }
        });
        // setFormError(null);
        // setFormName('Edit');
        // const invoiceRequest = axiosCtx.instance.get(`invoice/${item.id}`);
        // const costsRequest = axiosCtx.instance.get(`cost/job/${item.job.id}`);

        // Promise.all([costsRequest, invoiceRequest]).then(([costsResponse, invoicesResponse]) => {

        //     if (costsResponse.status === 200) {
        //         setCosts(() => costsResponse.data.data === undefined ? [] : costsResponse.data.data);
        //     }

        //     if (invoicesResponse.status === 200) {
        //         let response = invoicesResponse.data.data;
        //         console.log(response);
        //         setInvoiceId(response.id);
        //         setSelectedParty(response.party.id);
        //         setSelectedJob(response.job.id);
        //         setSelectedProject(response.project.id);
        //         setInvoiceCurrency(response.currency.id);
        //         setInvoiceDate(response.invoice_date);
        //         // setDestination(response.destination.id);
        //         setOurReference(response.our_reference);
        //         // setShowDestination(true);    
        //         setShow(true);
        //     }

        // }).catch((error) => {
        //     // TODO: Handle Error
        //     console.log(error)
        //     alert('Sorry, something went wrong!');
        // });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = invoices.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        event.stopPropagation();


        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const getCostsOfJob = (jobId) => {
        const costsRequest = axiosCtx.instance.get(`cost-invoice/job/${jobId}`);
        const jobRequest = axiosCtx.instance.get(`job/${jobId}`);

        Promise.all([costsRequest, jobRequest]).then(([costsResponse, jobsResponse]) => {

            if (costsResponse.status === 200) {
                setCosts(() => costsResponse.data.data === undefined ? [] : costsResponse.data.data);
            }

            if (jobsResponse.status === 200) {
                let response = jobsResponse.data.data[0];
                setSelectedParty(response.party);
                // setSelectedJob(response.id);
                setSelectedProject(response.project);
            }

        }).catch((error) => {
            // TODO: Handle Error
            alert('Sorry, something went wrong!');
        });
    };

    const findMultipleAirwaybills = (jobId) => {
        const destination = axiosCtx.instance.get(`cost/find-multiple-airwaybills/${jobId}`);

        Promise.all([destination]).then(([destinationResponse]) => {
            if (destinationResponse.status === 200) {
                setPorts(destinationResponse.data.data);
                setShowDestination(true);
            }

        }).catch((error) => {
            // TODO: Handle Error
        });
    };

    const jobChangeHandler = (event, value) => {
        setShowDestination(false);
        if (value) {
            getCostsOfJob(value.id);
            findMultipleAirwaybills(value.id);
            setSelectedJob(value.id);
            setSelectedParty('');
            setSelectedProject('');
        }

    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onDeleteCost = (index, i) => {
        let data = [...costs];
        delete data[index].costs[i];
        setCosts(data);
    }

    const onInvoiceSubmit = () => {
        setFormError(null);
        setLoading(true);
        // Call login API
        const params = {
            job_id: selectedJob,
            project_id: selectedProject,
            party_id: selectedParty,
            invoice_date: invoiceDate,
            currency_id: invoiceCurrency,
            destination: destination,
            our_reference: ourReference,
            costs_fields: JSON.stringify(costs)
        }

        if (invoiceId) {
            axiosCtx.instance.put(`invoice/` + invoiceId, params)
                .then((response) => {
                    setLoading(false);
                    getInvoices();
                    handleClose();
                })
                .catch((error) => {
                    setLoading(false);
                    setFormError(error.response.data);

                });
        } else {

            const formData = new FormData();
            formData.append("job_id", (selectedJob) ? selectedJob.id : '');
            formData.append("project_id", (selectedProject) ? selectedProject.id : '');
            formData.append("party_id", (selectedParty) ? selectedParty.id : '');
            formData.append("invoice_date", invoiceDate);
            formData.append("currency_id", (invoiceCurrency) ? invoiceCurrency.id : '');
            formData.append("destination", (destination) ? destination.id : '');
            formData.append("our_reference", ourReference);
            formData.append("amount", parseFloat(totalAmountAfterVat).toFixed(2));
            formData.append("costs_fields", JSON.stringify(costs));

            axiosCtx.instance.post(`invoice`, formData)
                .then((response) => {
                    setLoading(false);
                    getInvoices();
                    handleClose();
                })
                .catch((error) => {
                    setLoading(false);
                    setFormError(error.response.data);

                });
        }

    }

    const [startDate, setStartDate] = useState(new Date());
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setFormName('Create');
        setInvoiceId('');
        setSelectedParty('');
        setSelectedJob('');
        setSelectedProject('');
        setInvoiceCurrency('');
        setInvoiceDate('');
        setOurReference('');
        setDestination('');
        setShowDestination(false);
    }
    const handleShow = () => { setShow(true); setFormError(null); }
    const [showFilter, setShowFilter] = useState(false);
    // const filterClose = () => setShowFilter(false);

    const filterClose = () => {
        setShowFilter(false);
        // setStatus('');
        // setJobtype('');
        // setProject('');
        // setParty('');
        // getInvoices();
    }

    const filterClear = () => {
        setInvoiceDate('');
        setStatus(null);
        setProject(null);
        setJobtype(null);
        setParty(null);
        getInvoices();
    }


    const [jobtypes, setJobtypes] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [parties, setParties] = useState([]);
    const [projects, setProjects] = useState([]);
    const [currencys, setCurrency] = useState([]);
    const [costs, setCosts] = useState(null);
    const [showDestination, setShowDestination] = useState(false);
    const [ports, setPorts] = useState([]);
    const [destination, setDestination] = useState(null);
    const [ourReference, setOurReference] = useState('');

    const [selectedParty, setSelectedParty] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedJob, setSelectedJob] = useState('');
    const [invoiceId, setInvoiceId] = useState('');

    const [status, setStatus] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [party, setParty] = useState('');
    const [jobtype, setJobtype] = useState('');
    const [project, setProject] = useState('');

    const [amount, setAmount] = useState('');
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [date, setDate] = useState('');

    const [conversionRate, setConversionRate] = useState([]);

    // const currencyOptions = currencys.map(({
    //     currency_label: label,
    //     ...rest
    //   }) => ({
    //     label,
    //     ...rest
    //   }));


    const isSelected = (name) => selected.indexOf(name) !== -1;
    const [age, setAge] = React.useState('');
    const [invoiceDate, setInvoiceDate] = React.useState('');
    const [invoiceCurrency, setInvoiceCurrency] = React.useState('');
    const handleChange = (event) => { setAge(event.target.value); };
    const dateHandleChange = (event) => { setInvoiceDate(event.target.value); };
    const setSelectedDestination = (event) => { setDestination(event.target.value); };
    const currencyHandleChange = (event) => { setInvoiceCurrency(event.target.value); };

    const statusChangeHandler = (event, value) => {
        if (value) {
            setStatus(value.id);
        } else {
            setStatus('');
        }
    };
    const isFloat = (n) => {
        return Number(n) === n && n % 1 !== 0;
    }

    const ourReferenceHandleChange = (event) => {
        setOurReference(event.target.value);
    };
    const partyChangeHandler = (event, value) => {
        if (value) {
            setParty(value.id);
        } else {
            setParty('');
        }
    };
    const projectChangeHandler = (event, value) => {

        if (value) {
            setProject(value.id);
        } else {
            setProject('');
        }
    };

    const jobtypeChangeHandler = (event, value) => {

        if (value) {
            setJobtype(value.id);
        }
        else {
            setJobtype('');
        }
    };

    const getStatues = () => {
        axiosCtx.instance.get(`status`)
            .then((response) => {
                setStatuses(response.data.data);
            })
            .catch((error) => {
            });
    };

    const getJobtypes = () => {
        axiosCtx.instance.get(`job-type`)
            .then((response) => {
                setJobtypes(response.data.data);
            })
            .catch((error) => {
            });
    };

    const filterSubmit = (event) => {
        event.preventDefault();
        setFormError(null);
        setLoading(true);

        // Call login API
        const formData = new FormData();
        formData.append("job_type_id", (jobtype) ? jobtype.id : '');
        formData.append("status_id", (status) ? status.id : '');
        formData.append("project_id", (project) ? project.id : '');
        formData.append("party_id", (party) ? party.id : '');
        formData.append("invoice_date", invoiceDate);

        axiosCtx.instance.post(`invoice/filter`, formData)
            .then((response) => {
                setLoading(false);
                setInvoices(response.data.data);
                filterClose();
            })
            .catch((error) => {
                setLoading(false);
                setFormError(error.response.data);
            });
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoices.length) : 0;


    const getJobs = () => {
        axiosCtx.instance.get(`job`)
            .then((response) => {
                setJobs(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getParties = () => {
        axiosCtx.instance.get(`party`)
            .then((response) => {
                setParties(response.data.data);
            })
            .catch((error) => {
            });
    };

    const getProjects = () => {
        axiosCtx.instance.get(`project`)
            .then((response) => {
                setProjects(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getCurrency = () => {
        axiosCtx.instance.get(`currency`)
            .then((response) => {
                setCurrency(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getCurrencyConvertion = (currency_label) => {
        setLoading(true);
        axiosCtx.instance.get(`get-by-currency/` + currency_label)
            .then((response) => {
                setConversionRate(JSON.parse(response.data.data));
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
            });
    };

    let totalAmountBeforeVat = 0;
    let totalVatAmount = 0;
    let totalAmountAfterVat = 0;

    let con_revenue_amount = 0;
    let con_revenue_total = 0;

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#2f67c9");

    const purOptions = [
        { label: 'Product 1', id: 1 },
        { label: 'Add Product', id: 2 },
    ];

    return (
        <div className="content">
            {loading && loading == true ?
                <div className="custom-loader">
                    <FadeLoader color={color} loading={loading} css={override} size={150} />
                </div>
                : ''
            }
            <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
                <h4 className="font-weight-bold m-0">Invoice</h4>

                <div className="form-row align-items-center justify-content-end">
                    <div className="col-auto my-1 position-relative">
                        <button className="btn btn-simple" onClick={() => setShowFilter((currentShow) => !currentShow)} >
                            Filter
                        </button>
                        {showFilter ? (
                            <div className="filter-container p-4 bg-white shadow-sm rounded">
                                <Row>
                                    <Col md="6" className="mb-4">
                                        <InputDate
                                            id="date"
                                            placeholder="Dates"
                                            label="Dates"
                                            value={invoiceDate}
                                            onChange={dateHandleChange}
                                        />

                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <Dropdown
                                            id="statuses"
                                            placeholder="Status"
                                            label="name"
                                            value={status}
                                            options={statuses}
                                            onChange={(event, newValue) => {
                                                setStatus(newValue);
                                            }}
                                        />
                                    </Col>

                                    <Col md="6" className="mb-4">
                                        <Dropdown
                                            id="projects"
                                            placeholder="Project"
                                            label="name"
                                            value={project}
                                            options={projects}
                                            onChange={(event, newValue) => {
                                                setProject(newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <Dropdown
                                            id="parties"
                                            placeholder="Party"
                                            label="name"
                                            value={party}
                                            options={parties}
                                            onChange={(event, newValue) => {
                                                setParty(newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <Dropdown
                                            id="jobtypes"
                                            placeholder="Job Type"
                                            label="type"
                                            value={jobtype}
                                            options={jobtypes}
                                            onChange={(event, newValue) => {
                                                setJobtype(newValue);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <div className="text-right border-top pt-3">
                                    <Button
                                        className="my-0 mr-3"
                                        variant="secondary"
                                        onClick={filterClear}
                                    >
                                        Clear
                                    </Button>
                                    <Button
                                        className="my-0 mr-3"
                                        variant="secondary"
                                        onClick={filterClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="my-0  "
                                        variant="primary"
                                        onClick={filterSubmit}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="col-auto my-1">
                        <button className="btn btn-primary" onClick={handleShow}>
                            Create Invoice
                        </button>
                    </div>
                </div>

                {/* ******************** modal start here **************** */}

                <Modal show={show} onHide={handleClose} size="xl" >
                    <Modal.Header className="p-4">
                        <Modal.Title className="m-0">{formName} Invoice </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <Row className="mb-4">
                            <Col md="4" className="my-4">
                                <Dropdown
                                    id="customer"
                                    placeholder="Customer"
                                    label="customer"
                                    value={project}
                                    options={projects}
                                    onChange={(event, newValue) => {
                                        setProject(newValue);
                                    }}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="InvoiceNumber"
                                    placeholder="Invoice Number"
                                    label="Invoice Number"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="Customer-Code"
                                    placeholder="Customer Code"
                                    label="Customer Code"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="Billing-cycle-name"
                                    placeholder="Billing cycle name"
                                    label="Billing cycle name"
                                    value={name}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.name) ? formError.errors.name : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="Customer-Name"
                                    placeholder="CustomerName"
                                    label="Customer Name"
                                    value={name}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.name) ? formError.errors.name : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputDate
                                    id="Billdate"
                                    placeholder="Bill date"
                                    label="Bill date"
                                    value={invoiceDate}
                                    onChange={dateHandleChange}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="Email"
                                    placeholder="Email"
                                    label="Email"
                                    value={name}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.name) ? formError.errors.name : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputDate
                                    id="Duedate"
                                    placeholder="Due date"
                                    label="Due date"
                                    value={invoiceDate}
                                    onChange={dateHandleChange}
                                />
                            </Col>

                            <Col md="4" className="my-4">
                                <InputField
                                    id="Mobile"
                                    placeholder="Mobile"
                                    label="Mobile"
                                    value={amount}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.amount) ? formError.errors.amount : ''}
                                />
                            </Col>
                        </Row>
                        <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
                            Add particulars
                        </h6>
                        <Row>

                            <Table className="table-bordered mt-4 mb-0" >
                                <thead className="font-weight-bold">
                                    <tr>
                                        <td> Description  </td>
                                        <td> Sac  Code </td>
                                        <td> Time Period  </td>
                                        <td> GST  </td>
                                        <td> Absolute Cost  </td>
                                        <td> Action </td>
                                    </tr>
                                </thead>

                                <tr>
                                    <td>
                                        <Dropdown
                                            id="particular"
                                            placeholder="particular"
                                            label="label"
                                            value={status}
                                            options={purOptions}
                                            onChange={(event, newValue) => {
                                                if (newValue && newValue.id == 2) {
                                                    setShowProduct(true);
                                                }
                                            }}
                                        />
                                    </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td>
                                        <Dropdown
                                            id="gst"
                                            placeholder="GST"
                                            label="GST"
                                            value={status}
                                            options={statuses}
                                            onChange={(event, newValue) => {
                                                setStatus(newValue);
                                            }}
                                        />

                                    </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td>
                                        <DeleteIcon
                                            className="text-danger cursor-p">
                                        </DeleteIcon>
                                    </td>

                                </tr>
                                <tr className="font-weight-bold">
                                    <td > <InputField
                                        id="Sub Total"
                                        placeholder="Sub Total"
                                        label="Sub Total"
                                        value=""
                                    />  </td>
                                    <td >

                                    </td>
                                    <td >

                                    </td>
                                    <td >

                                    </td>
                                    <td >
                                        <InputField
                                            id="Total"
                                            placeholder=""
                                            label="Total"
                                            value="0.00"
                                        />
                                    </td>
                                    <td>
                                        <Button className="mr-3"
                                            variant="secondary"
                                        >
                                            Add
                                        </Button>
                                    </td>
                                </tr>
                            </Table>

                        </Row>
                        <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
                            Add taxes
                        </h6>
                        <Row>

                            <Table className="table-bordered mt-4 mb-0" >
                                <thead className="font-weight-bold">
                                    <tr>
                                        <td> Tax label  </td>
                                        <td> Tax in % </td>
                                        <td>Applicable on </td>
                                        <td> Absolute Cost  </td>
                                        <td>  </td>
                                    </tr>
                                </thead>

                                <tr>
                                    <td>
                                        <Dropdown
                                            id="particular"
                                            placeholder="CGST@9%"
                                            label="particular"
                                            value={status}
                                            options={statuses}
                                            onChange={(event, newValue) => {
                                                setStatus(newValue);
                                            }}
                                        />
                                    </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td>
                                        <DeleteIcon
                                            className="text-danger cursor-p">
                                        </DeleteIcon>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <Dropdown
                                            id="particular"
                                            placeholder="SGST@9%"
                                            label="particular"
                                            value={status}
                                            options={statuses}
                                            onChange={(event, newValue) => {
                                                setStatus(newValue);
                                            }}
                                        />
                                    </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td> <TextField value="" size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                                    <td>
                                        <DeleteIcon
                                            className="text-danger cursor-p">
                                        </DeleteIcon>
                                    </td>

                                </tr>
                                <tr className="font-weight-bold">
                                    <td > <InputField
                                        id="Tax Total"
                                        placeholder="Tax Total"
                                        label="Tax Total"
                                        value=""
                                    />  </td>
                                    <td >

                                    </td>
                                    <td >

                                    </td>
                                    <td >
                                        <InputField
                                            id="Total"
                                            placeholder=""
                                            label="Total"
                                            value="0.00"
                                        />
                                    </td>
                                    <td>
                                        <Button className="mr-3"
                                            variant="secondary"
                                        >
                                            Add
                                        </Button>
                                    </td>
                                </tr>
                            </Table>

                        </Row>
                        <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
                            Final Summary
                        </h6>
                        <Row>
                            <Col md="3" className="my-4">
                                <InputField
                                    id="InvoiceNumber"
                                    placeholder="Fee value with taxes"
                                    label="Fee value with taxes"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="3" className="my-4">
                                <InputField
                                    id="GrandTotal"
                                    placeholder="Grand Total"
                                    label="Grand Total"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="3" className="my-4">
                                <InputField
                                    id="Narrative"
                                    placeholder="Narrative"
                                    label="Narrative"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="3" className="my-4">
                                <FormGroup>
                                    <FormControlLabel control={<Switch defaultChecked />} label="Notify Customer" />
                                </FormGroup>
                            </Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer className="p-4">
                        <Button className="mr-3" variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={onInvoiceSubmit}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* ******************** delete modal start here **************** */}
                <Modal show={showProduct} onHide={handleCloseProduct} size="xl">
                    <Modal.Header className="p-4">
                        <Modal.Title className="m-0">Create Product/Service </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
                            Basic details
                        </h6>
                        <Row className="mb-4">
                            <Col md="4" className="my-4">
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="product" control={<Radio />} label="Product" />
                                        <FormControlLabel value="service" control={<Radio />} label="Service" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>

                            <Col md="4" className="my-4">
                                <InputField
                                    id="SKU"
                                    placeholder="SKU"
                                    label="SKU"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <Dropdown
                                    id="Product Type"
                                    placeholder="Product Type"
                                    label="Product Type"
                                    value={project}
                                    options={projects}
                                    onChange={(event, newValue) => {
                                        setProject(newValue);
                                    }}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <Dropdown
                                    id="GST applicable"
                                    placeholder="GST applicable"
                                    label="GST applicable"
                                    value={project}
                                    options={projects}
                                    onChange={(event, newValue) => {
                                        setProject(newValue);
                                    }}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="Product Name"
                                    placeholder="Product Name"
                                    label="Product Name"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <Dropdown
                                    id="Select category"
                                    placeholder="Select category"
                                    label="Select category"
                                    value={project}
                                    options={projects}
                                    onChange={(event, newValue) => {
                                        setProject(newValue);
                                    }}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="Product Number"
                                    placeholder="Product Number"
                                    label="Product Number"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <Dropdown
                                    id="Select vendor"
                                    placeholder="Select vendor"
                                    label="Select vendor"
                                    value={project}
                                    options={projects}
                                    onChange={(event, newValue) => {
                                        setProject(newValue);
                                    }}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="HSN Code"
                                    placeholder="HSN Code"
                                    label="HSN Code"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <label htmlFor="raised-button-file">Product Image:
                                </label>
                                <input
                                    accept="image/*"
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <Dropdown
                                    id="Select unit type"
                                    placeholder="Select unit type"
                                    label="Select unit type"
                                    value={project}
                                    options={projects}
                                    onChange={(event, newValue) => {
                                        setProject(newValue);
                                    }}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputField
                                    id="Description"
                                    placeholder="Description"
                                    label="Description"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="4" className="my-4">
                                <InputDate
                                    id="Expiry Date"
                                    placeholder="Expiry date"
                                    label="Expiry date"
                                    value={invoiceDate}
                                    onChange={dateHandleChange}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" className="my-4">
                                <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
                                    Sales Information
                                </h6>

                            </Col>
                            <Col md="6" className="my-4">
                                <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
                                    Purchase Information
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" className="my-4">
                                <InputField
                                    id="Sale price"
                                    placeholder="Sale price"
                                    label="Sale price"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4">
                                <InputField
                                    id="Cost price"
                                    placeholder="Cost price"
                                    label="Cost price"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4">
                                <InputField
                                    id="MRP"
                                    placeholder="MRP"
                                    label="MRP"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4">
                                <InputField
                                    id="Description"
                                    placeholder="Description"
                                    label="Description"
                                    value={code}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4">
                                <InputField
                                    id="Description"
                                    placeholder="Description"
                                    label="Description"
                                    value={code}
                                    multiline="multiline"
                                    rows={4}
                                    onChange={(event) => {
                                        setCode(event.target.value);
                                    }}
                                    formError={(formError && formError.errors.code) ? formError.errors.code : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4"> 
                            </Col>
                            <Col md="6" className="my-4">
                                <FormGroup>
                                    <FormControlLabel control={<Switch defaultChecked />} label="Manage inventory" />
                                </FormGroup>
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="p-4">
                        <Button
                            className="mr-3"
                            variant="secondary"
                            onClick={handleCloseProduct}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={deleteSubmitHandler}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* ******************** delete modal start here **************** */}
                <Modal show={showDelete} onHide={handleCloseDelete} size="lg">
                    <Modal.Header className="p-4">
                        <Modal.Title className="m-0">Delete Invoice </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
                        <p>{deleteMessage}</p>
                    </Modal.Body>
                    <Modal.Footer className="p-4">
                        <Button
                            className="mr-3"
                            variant="secondary"
                            onClick={handleCloseDelete}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={deleteSubmitHandler}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>

            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={invoices.length}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                                {stableSort(invoices, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" padding="none" > {row.invoice_number} </TableCell>
                                                <TableCell>{row.job_type.type}</TableCell>
                                                <TableCell>{row.project.name}</TableCell>
                                                <TableCell>{row.party.name}</TableCell>
                                                <TableCell>{row.invoice_date}</TableCell>
                                                <TableCell>{row.currency.currency_label} {Number(row.amount).toFixed(2)}</TableCell>
                                                <TableCell>
                                                    <button onClick={() => onEditClickHandler(row)} className="btn btn-primary btn-sm font-weight-bold ">Edit</button>
                                                    <button onClick={() => onPrintClickHandler(row)} className="btn btn-info btn-sm font-weight-bold ">Print</button>
                                                    <button onClick={() => onDeleteClickHandler(row)} className="btn btn-danger btn-sm font-weight-bold ">Delete</button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={invoices.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>

            </Box>
        </div>
    );
}
