import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Accordion } from 'react-bootstrap'
import { useParams } from "react-router-dom";

// reactstrap components
import {
    Card,
    Table,
    Row,
    Col,
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import AxiosContext from '../store/axios-context';
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';
import Stack from '@mui/material/Stack';
import { useLocation } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import Dropdown from "./Fields/Dropdown";
import InputDate from "./Fields/InputDate";
import InputField from "./Fields/InputField";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function AirwayBillDetail(props) {
    const today = new Date();
    let defaultDate = today.toLocaleDateString('en-CA');
    // let currentTime = today.toISOString().substring(11, 16);
    let currentTime = today.getHours() + ':' + today.getMinutes();
    // const classes = useStyles();
    const [formError, setFormError] = useState(null);
    const axiosCtx = useContext(AxiosContext);
    const authCtx = useContext(AuthContext);
    const history = useHistory();
    const [showAccountCode, setShowAccountCode] = useState(false);
    const [ShowVolume, setShowVolume] = useState(false);
    const [ShowOtherCharges, setShowOtherCharges] = useState(false);
    const [ShowAWBAccount, setShowAWBAccount] = useState(false);
    const location = useLocation();

    const accOptions = [
        { label: "Prepaid", id: 1 },
        { label: "Charges Collect", id: 2 },
    ];

    const [age, setAge] = React.useState('');
    const handleChange = (event) => { setAge(event.target.value); };
    const volumeClose = () => setShowVolume(false);
    const volumeShow = () => setShowVolume(true);
    const AWBAccountClose = () => setShowAWBAccount(false);
    const AWBAccountShow = () => setShowAWBAccount(true);
    const accountCodeClose = () => setShowAccountCode(false);
    const accountCodeShow = (index) => {
        setShowAccountCode(true);
        setCodeIndex(index);
    };
    const onAccDescChange = (i, e) => {
        let formValues = [...accFormValues];
        formValues[i][e.target.name] = e.target.value;
        setAccFormValues(formValues);
    }
    const onClickCode = (index) => {
        let codesData = [...codes];
        let accountCodes = [...accFormValues];
        accountCodes[codeIndex]['code'] = codesData[index]['code'];
        setAccFormValues(accountCodes);
        setShowAccountCode(false)
    }
    const calculatePrepaidCollect = () => {
        const totPre = otherChargePrinting.reduce((total, currentValue) => (currentValue.payment_mode == "1") ? total = parseInt(total) + parseInt(currentValue.amount) : total = total, 0);
        setTotalPrepaid(totPre);

        const totalCol = otherChargePrinting.reduce((total, currentValue) => (currentValue.payment_mode == "2") ? total = parseInt(total) + parseInt(currentValue.amount) : total = total, 0);
        setTotalCollect(totalCol);

        let grndTotal = parseFloat(totPre) + parseFloat(totalCol) + parseFloat(totalIataRate);

        setGrandTotal(Math.round(grndTotal.toFixed(2)));
    }
    const otherChargesClose = () => {
        setShowOtherCharges(false);
        calculatePrepaidCollect();
    }
    const otherChargesShow = () => setShowOtherCharges(true);
    const [codeIndex, setCodeIndex] = useState();

    const [jobId, setJobId] = useState([]);
    const [shipper, setShipper] = useState(null);
    const [consignee, setConsignee] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [piece, setPiece] = useState('');
    const [weight, setWeight] = useState('');
    const [volume, setVolume] = useState(0);
    const [volumetricWeight, setVolumetricWeight] = useState(0);
    const [ntrOfGoods, setNtrOfGoods] = useState('');
    const [pickupLoc, setPickupLoc] = useState('');
    const [shc, setShc] = useState('');
    const [hawbhcvbNo, setHawbhcvbNo] = useState('');
    const [prefixes, setPrefixes] = useState([]);
    const [prefix, setPrefix] = useState(null);
    const [mawbmcvbNo, setMawbmcvbNo] = useState('');
    const [locations, setLocations] = useState([]);
    const [loc, setLoc] = useState(null);
    const [racks, setRacks] = useState([]);
    const [rack, setRack] = useState(null);
    const [totalPackage, setTotalPackage] = useState('');
    const [packageInDate, setPackageInDate] = useState('');

    const [originAirport, setOriginAirport] = useState('');
    const [finalDestination, setFinalDestination] = useState('');
    const [destTo1, setDestTo1] = useState('');
    const [destFlightNo1, setDestFlightNo1] = useState('');
    const [destFlightDate1, setDestFlightDate1] = useState('');
    const [onwdTo1, setOnwdTo1] = useState('');
    const [onwdFlightNo1, setOnwdFlightNo1] = useState('');
    const [onwdFlightDate1, setOnwdFlightDate1] = useState('');
    const [onwdTo2, setOnwdTo2] = useState('');
    const [onwdFlightNo2, setOnwdFlightNo2] = useState('');
    const [onwdFlightDate2, setOnwdFlightDate2] = useState('');

    const [handlingInformation, setHandlingInformation] = useState('');
    const [additionalInformation, setAdditionalInformation] = useState('');
    const [ssrInformation, setSsrInformation] = useState('');

    const [currency, setCurrency] = useState(null);
    const [accCurrency, setAccCurrency] = useState('AED');
    const [decCustoms, setDecCustoms] = useState('NCV');
    const [decCarriage, setDecCarriage] = useState('NVD');
    const [currencies, setCurrencies] = useState([]);

    const [parties, setParties] = useState([]);
    const [ports, setPorts] = useState([]);


    const [totalDimsPieces, setTotalDimsPieces] = useState('');
    const [volumeDims, setVolumeDims] = useState('');
    const [volumetricWeightDims, setVolumetricWeightDims] = useState('');


    const [accWeight, setAccWeight] = useState(null);
    const [dueAgent, setDueAgent] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [dueCarrier, setDueCarrier] = useState('');
    const [modeOfPayment, setModeOfPayment] = useState('');
    const [productCode, setProductCode] = useState('');
    const [valuationCharge, setValuationCharge] = useState('');
    const [customsOrigin, setCustomsOrigin] = useState('');
    const [currencyConvertion, setCurrencyConvertion] = useState('');


    const [signDate, setSignDate] = useState(defaultDate);
    const [signTime, setSignTime] = useState(currentTime);
    const [signPlace, setSignPlace] = useState('DUBAI');
    const [customSerialNo, setCustomSerialNo] = useState('');
    const [signOfShipper, setSignOfShipper] = useState('SEA TRUST SHIPPING LLC, DXB');
    const [signOfCarrier, setSignOfCarrier] = useState(authCtx.name);


    const [weightUnit, setWeightUnit] = useState('Kg');
    const [rateClass, setRateClass] = useState('Q');
    const [iataRate, setIataRate] = useState(0);
    const [totalIataRate, setTotalIataRate] = useState('');
    const [natureOfGoods, setNatureOfGoods] = useState('');

    const [totalPrepaid, setTotalPrepaid] = useState(0);
    const [totalCollect, setTotalCollect] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [airwayBillId, setAirwayBillId] = useState(0);

    const [dimsFormValues, setDimsFormValues] = useState([{ piece: "", unit: "", length: "", width: "", height: "", volume: "", volumetric_weight: "" }])

    const addDimsField = () => {
        setDimsFormValues([...dimsFormValues, { piece: "", unit: "", length: "", width: "", height: "", volume: "", volumetric_weight: "" }])
    };

    let codes = [
        { "code": "CRD", "description": "Credit card Expiry Date", "info": "ACCTINFO" },
        { "code": "CRI", "description": "Credit Card Insurance Name (Name Shown on the Credit Card)", "info": "ACCTINFO" },
        { "code": "CRN", "description": "Credit Card Number", "info": "ACCTINFO" },
        { "code": "GBL", "description": "Government Bill of Lading ", "info": "ACCTINFO" },
        { "code": "GEN", "description": "General Information", "info": "ACCTINFO" },
        { "code": "MCO", "description": "Miscellaneous Charge Order", "info": "ACCTINFO" },
        { "code": "RET", "description": "Return to Shipper", "info": "ACCTINFO" },
        { "code": "SRN", "description": "Shipper's Reference Number", "info": "ACCTINFO" },
        { "code": "STL", "description": "Mode Of Settlement", "info": "ACCTINFO" },
    ];

    let removeDimsField = (i, e) => {
        let newFormValues = [...dimsFormValues];
        newFormValues.splice(i, 1);
        setDimsFormValues(newFormValues)

        const pieceTotal = newFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.piece) && currentValue.piece > 0)) ? total = parseInt(total) + parseInt(currentValue.piece) : total = total, 0);
        setTotalDimsPieces(pieceTotal);
        setPiece(pieceTotal);
        setTotalPackage(pieceTotal);

        const totVol = newFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.volume) && currentValue.volume > 0)) ? total = parseFloat(total) + parseFloat(currentValue.volume) : total = total, 0);
        setVolume(totVol);

        const totVolMet = newFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.volumetric_weight) && currentValue.volumetric_weight > 0)) ? total = parseFloat(total) + parseFloat(currentValue.volumetric_weight) : total = total, 0);
        setVolumetricWeightDims(Math.round(totVolMet));

    }

    const dimsFieldsHandleChange = (i, e) => {
        let formValues = [...dimsFormValues];
        formValues[i][e.target.name] = e.target.value;
        setDimsFormValues(formValues);
    }
    const dimsFieldsFocusOut = (i, e) => {
        const pieceTotal = dimsFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.piece) && currentValue.piece > 0)) ? total = parseInt(total) + parseInt(currentValue.piece) : total = total, 0);
        setTotalDimsPieces(pieceTotal);
        setPiece(pieceTotal);
        setTotalPackage(pieceTotal);

        let formValues = [...dimsFormValues];
        let volMetricWe = (formValues[i]["piece"] * formValues[i]["length"] * formValues[i]["height"] * formValues[i]["width"]) / 6000;
        let vol = volMetricWe.toFixed(2) / 166.67;
        formValues[i]["volume"] = vol.toFixed(2);
        formValues[i]["volumetric_weight"] = volMetricWe.toFixed(2);
        setDimsFormValues(formValues);

        const totVol = dimsFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.volume) && currentValue.volume > 0)) ? total = parseFloat(total) + parseFloat(currentValue.volume) : total = total, 0);
        setVolumeDims(totVol.toFixed(2));
        setVolume(totVol.toFixed(2));

        const totVolMet = dimsFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.volumetric_weight) && currentValue.volumetric_weight > 0)) ? total = parseFloat(total) + parseFloat(currentValue.volumetric_weight) : total = total, 0);
        setVolumetricWeightDims(Math.round(totVolMet));

    }

    const [accFormValues, setAccFormValues] = useState([{ code: "", accounting: "" }]);

    const addAccField = () => {
        setAccFormValues([...accFormValues, { code: "", accounting: "" }]);
    };

    let removeAccField = (data) => {
        let newFormValues = [...accFormValues];
        newFormValues.splice(data.index, 1);
        setAccFormValues(newFormValues);
    }

    const [otherChargePrinting, setOtherChargePrinting] = useState([{ charge_code: "", payment_mode: "", agent_airline: "", amount: "" }]);

    const addOtherChargePrinting = () => {
        setOtherChargePrinting([...otherChargePrinting, { charge_code: "", payment_mode: "", agent_airline: "", amount: "" }]);
    };

    let removeOtherChargePrinting = (data) => {
        let newFormValues = [...otherChargePrinting];
        newFormValues.splice(data.index, 1);
        setOtherChargePrinting(newFormValues);
    }

    let onOtherChargePrintingChange = (i, e) => {
        let formValues = [...otherChargePrinting];
        formValues[i][e.target.name] = e.target.value;
        setOtherChargePrinting(formValues);
    }

    const [otherChargeFormValues, setOtherChargeFormValues] = useState([{ no_of_piece: "", gross_wt: "", charge_wt: "", iata_rate: "", total_iata_rate: "", nature_of_goods: "" }]);


    const getParties = () => {
        axiosCtx.instance.get(`party`)
            .then((response) => {
                setParties(response.data.data);
            })
            .catch((error) => {
            });
    };

    const getAirlinePrefixes = () => {
        axiosCtx.instance.get(`airline_prefix`)
            .then((response) => {
                setPrefixes(response.data.data);
            })
            .catch((error) => {
            });
    };

    const getPorts = () => {
        axiosCtx.instance.get(`port`)
            .then((response) => {
                setPorts(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    const getLocations = () => {
        axiosCtx.instance.get(`location`)
            .then((response) => {
                setLocations(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    const getRacks = () => {
        axiosCtx.instance.get(`rack`)
            .then((response) => {
                setRacks(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    const shipperHandleChange = (event) => {
        setShipper(event.target.value);
    };

    const consigneeHandleChange = (event) => {
        setConsignee(event.target.value);
    };

    // const locationHandleChange = (event) => {
    //     setLocation(event.target.value);
    // };
    // const rackHandleChange = (event) => {
    //     setRack(event.target.value);
    // };

    const totalPackageHandleChange = (event) => {
        setTotalPackage(event.target.value);
    };

    const packageInDateHandleChange = (event) => {
        setPackageInDate(event.target.value);
    };

    const prefixHandleChange = (event) => {
        setPrefix(event.target.value);
    };

    const originHandleChange = (event) => {
        setOrigin(event.target.value);
    };

    const destinationHandleChange = (event) => {
        setDestination(event.target.value);
    };

    const pieceHandleChange = (event) => {
        setPiece(event.target.value);
        setTotalPackage(event.target.value);
    };

    const weightHandleChange = (event) => {
        setWeight(event.target.value);
    };

    const volumeHandleChange = (event) => {
        setVolume(event.target.value);
    };

    const ntrOfGoodsHandleChange = (event) => {
        setNtrOfGoods(event.target.value);
    };

    const pickupLocHandleChange = (event) => {
        setPickupLoc(event.target.value);
    };

    const shcHandleChange = (event) => {
        setShc(event.target.value);
    };

    const hawbNoHandleChange = (event) => {
        setHawbhcvbNo(event.target.value);
    };

    const mawbNoHandleChange = (event) => {
        setMawbmcvbNo(event.target.value);
    };

    const originAirportHandleChange = (event) => {
        setOriginAirport(event.target.value);
    };

    const finalDestinationHandleChange = (event) => {
        setFinalDestination(event.target.value);
    };

    const handlingInformationHandleChange = (event) => {
        setHandlingInformation(event.target.value);
    };

    const additionalInformationHandleChange = (event) => {
        setAdditionalInformation(event.target.value);
    };

    const ssrInformationHandleChange = (event) => {
        setSsrInformation(event.target.value);
    };

    const destTo1HandleChange = (event) => {
        setDestTo1(event.target.value);
    };

    const destFlightNo1HandleChange = (event) => {
        setDestFlightNo1(event.target.value);
    };

    const destFlightDate1HandleChange = (event) => {
        setDestFlightDate1(event.target.value);
    };

    const onwdTo1HandleChange = (event) => {
        setOnwdTo1(event.target.value);
    };

    const onwdFlightNo1HandleChange = (event) => {
        setOnwdFlightNo1(event.target.value);
    };

    const onwdFlightDate1HandleChange = (event) => {
        setOnwdFlightDate1(event.target.value);
    };

    const onwdTo2HandleChange = (event) => {
        setOnwdTo2(event.target.value);
    };

    const onwdFlightNo2HandleChange = (event) => {
        setOnwdFlightNo2(event.target.value);
    };

    const onwdFlightDate2HandleChange = (event) => {
        setOnwdFlightDate2(event.target.value);
    };

    const currencyHandleChange = (event) => {
        setCurrency(event.target.value);
    };

    const accCurrencyHandleChange = (event) => {
        setAccCurrency(event.target.value);
    };

    const decCustomsHandleChange = (event) => {
        setDecCustoms(event.target.value);
    };

    const decCarriageHandleChange = (event) => {
        setDecCarriage(event.target.value);
    };

    const accWeightHandleChange = (event) => {
        setAccWeight(event.target.value);
    };

    const dueAgentHandleChange = (event) => {
        setDueAgent(event.target.value);
    };

    const paymentMethodHandleChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const dueCarrierHandleChange = (event) => {
        setDueCarrier(event.target.value);
    };

    const modeOfPaymentHandleChange = (event) => {
        setModeOfPayment(event.target.value);
    };

    const productCodeHandleChange = (event) => {
        setProductCode(event.target.value);
    };

    const valuationChargeHandleChange = (event) => {
        setValuationCharge(event.target.value);
    };

    const customsOriginHandleChange = (event) => {
        setCustomsOrigin(event.target.value);
    };

    const currencyConvertionHandleChange = (event) => {
        setCurrencyConvertion(event.target.value);
    };

    const signDateHandleChange = (event) => {
        setSignDate(event.target.value);
    };
    const signTimeHandleChange = (event) => {
        setSignTime(event.target.value);
    };
    const signPlaceHandleChange = (event) => {
        setSignPlace(event.target.value);
    };
    const customSerialNoHandleChange = (event) => {
        setCustomSerialNo(event.target.value);
    };
    const signOfShipperHandleChange = (event) => {
        setSignOfShipper(event.target.value);
    };

    const signOfCarrierHandleChange = (event) => {
        setSignOfCarrier(event.target.value);
    };

    const totalPrepaidHandleChange = (event) => {
        setTotalPrepaid(event.target.value);
    };

    const totalCollectHandleChange = (event) => {
        setTotalCollect(event.target.value);
    };

    const grandleTotalHandleChange = (event) => {
        setGrandTotal(event.target.value);
    };

    const weightUnitHandleChange = (event) => {
        setWeightUnit(event.target.value);
    };

    const rateClassHandleChange = (event) => {
        setRateClass(event.target.value);
    };

    const iataRateHandleChange = (event) => {
        setIataRate(event.target.value);
    };

    const calculateTotalIata = () => {
        let iatatotal = iataRate * volume;
        setTotalIataRate(Math.round(iatatotal.toFixed(2)));
    }

    const natureOfGoodsHandleChange = (event) => {
        setNatureOfGoods(event.target.value);
    };


    const getCurrencies = () => {
        axiosCtx.instance.get(`currency`)
            .then((response) => {
                setCurrencies(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };
    const { id } = useParams();
    // console.log("id");
    // console.log(id);
    useEffect(() => {
        if (authCtx.isLoggedIn == false) {
            history.replace('/auth/Login');
        }
        if (id) {
            getAirwayBillById(id);
            setAirwayBillId(id);
        }
        if (location.state && location.state.data != null) {
            setJobId(location.state.data.job_id);
        }
        getPorts();
        getParties();
        getCurrencies();
        getAirlinePrefixes();
        getLocations();
        getRacks();
    }, []);

    const getAirwayBillById = (airwaybill_id) => {
        setLoading(true);
        const airwaybillRequest = axiosCtx.instance.get(`airway-bill/${airwaybill_id}`);

        Promise.all([airwaybillRequest]).then(([airwaybillResponse]) => {

            if (airwaybillResponse.status === 200) {
                let response = airwaybillResponse.data.data;
                setJobId(response.job_id ? response.job_id : '');
                // setWarehouse(response.warehouse ? response.warehouse : '');
                setShipper(response.shipper ? response.shipper : '');
                setOrigin(response.origin ? response.origin : '');
                setDestination(response.destination ? response.destination : '');
                setPiece(response.piece ? response.piece : '');
                setWeight(response.weight ? response.weight : '');
                setVolume(response.volume ? response.volume : '');
                setNtrOfGoods(response.ntr_of_goods ? response.ntr_of_goods : '');
                setPickupLoc(response.pickup_loc ? response.pickup_loc : '');
                setShc(response.shc ? response.shc : '');
                setHawbhcvbNo(response.hawb_hcbv_no ? response.hawb_hcbv_no : '');
                setPrefix(response.prefix);
                setMawbmcvbNo(response.mawb_mcbv_no);
                setConsignee(response.consignee ? response.consignee : '');
                setHandlingInformation(response.handling_information ? response.handling_information : '');
                setAdditionalInformation(response.additional_information ? response.additional_information : '');
                setSsrInformation(response.ssr_information ? response.ssr_information : '');
                setCurrency(response.currency ? response.currency : '');
                setDecCustoms(response.dec_customs);
                setDecCarriage(response.dec_carriage);

                setOriginAirport(response.routing_details.origin_airport ? response.routing_details.origin_airport : '');
                setFinalDestination(response.routing_details.final_destination ? response.routing_details.final_destination : '');
                setDestTo1(response.routing_details.dest_to_1 ? response.routing_details.dest_to_1 : '');
                setDestFlightNo1(response.routing_details.dest_flight_number_1 ? response.routing_details.dest_flight_number_1 : '');
                setDestFlightDate1(response.routing_details.dest_flight_date_1 ? response.routing_details.dest_flight_date_1 : '');
                setOnwdTo1(response.routing_details.onwd_to_1 ? response.routing_details.onwd_to_1 : '');
                setOnwdFlightNo1(response.routing_details.onwd_flight_number_1 ? response.routing_details.onwd_flight_number_1 : '');
                setOnwdFlightDate1(response.routing_details.onwd_flight_date_1 ? response.routing_details.onwd_flight_date_1 : '');
                setOnwdTo2(response.routing_details.onwd_to_2 ? response.routing_details.onwd_to_2 : '');
                setOnwdFlightNo2(response.routing_details.onwd_flight_number_2 ? response.routing_details.onwd_flight_number_2 : '');
                setOnwdFlightDate2(response.routing_details.onwd_flight_date_2 ? response.routing_details.onwd_flight_date_2 : '');

                setAccCurrency(response.currency ? response.currency : '');
                setDecCarriage(response.accounting_information.dec_carriage ? response.accounting_information.dec_carriage : '');
                setDecCustoms(response.accounting_information.dec_customs ? response.accounting_information.dec_customs : '');
                setAccWeight(response.accounting_information.weight ? response.accounting_information.weight : '');
                setDueAgent(response.accounting_information.due_agent_dha ? response.accounting_information.due_agent_dha : '');
                setPaymentMethod(response.accounting_information.payment_method ? response.accounting_information.payment_method : '');
                setDueCarrier(response.accounting_information.due_carrier ? response.accounting_information.due_carrier : '');
                setModeOfPayment(response.accounting_information.mode_of_payment ? response.accounting_information.mode_of_payment : '');
                setProductCode(response.accounting_information.product_code ? response.accounting_information.product_code : '');
                setValuationCharge(response.accounting_information.valuation_charge ? response.accounting_information.valuation_charge : '');
                setCustomsOrigin(response.accounting_information.customs_origin ? response.accounting_information.customs_origin : '');
                setCurrencyConvertion(response.accounting_information.currency_conversion ? response.accounting_information.currency_conversion : '');

                setSignPlace(response.place ? response.place : '');
                setSignDate(response.date ? response.date : '');
                setSignTime(response.time ? response.time : '');
                setSignOfShipper(response.sign_of_shipper ? response.sign_of_shipper : '');
                setSignOfCarrier(response.sign_of_carrier ? response.sign_of_carrier : '');

                setTotalPrepaid(response.total_prepaid ? response.total_prepaid : '');
                setTotalCollect(response.total_collect ? response.total_collect : '');
                setGrandTotal(response.grand_total ? response.grand_total : '');

                setWeightUnit(response.weight_unit ? response.weight_unit : '');
                setRateClass(response.rate_class ? response.rate_class : '');
                setIataRate(response.iata_rate ? response.iata_rate : '');
                setTotalIataRate(response.total_iata_rate ? response.total_iata_rate : '');
                setNatureOfGoods(response.nature_of_goods ? response.nature_of_goods : '');



                setOtherChargePrinting(JSON.parse(response.other_charge_printing_fields));

                setTotalDimsPieces(response.dims_total_piece ? response.dims_total_piece : '');
                setVolumeDims(response.dims_total_volume ? response.dims_total_volume : '');
                setVolumetricWeightDims(response.dims_total_volumetric_weight ? response.dims_total_volumetric_weight : '');
                setDimsFormValues(JSON.parse(response.dims_fileds));

                setAccFormValues(JSON.parse(response.accounting_codes));
                // setOtherChargeFormValues(JSON.parse(response.other_charge_fields));


                setLoc(response.warehouse.location ? response.warehouse.location : null);
                setRack(response.warehouse.rack ? response.warehouse.rack : null);
                setTotalPackage(response.warehouse.total_packages ? response.warehouse.total_packages : '');
                setPackageInDate(response.warehouse.packages_in_date ? response.warehouse.packages_in_date : '');
                setLoading(false);

            }

        }).catch((error) => {
            // TODO: Handle Error
            console.log(error)
            alert('Sorry, something went wrong!');
            setLoading(false);
        });
    };


    const submitHandler = (event) => {
        event.preventDefault();
        setFormError(null);
        setLoading(true);

        // Call login API
        const formData = new FormData();
        formData.append("job_id", jobId);
        // formData.append("warehouse_id", (warehouse) ? warehouse.id : '');
        formData.append("shipper", (shipper) ? shipper.id : '');
        formData.append("origin", (origin) ? origin.id : '');
        formData.append("destination", (destination) ? destination.id : '');
        formData.append("piece", piece);
        formData.append("weight", weight);
        formData.append("volume", volume);
        formData.append("ntr_of_goods", ntrOfGoods);
        formData.append("pickup_loc", pickupLoc);
        formData.append("shc", shc);
        formData.append("hawb_hcbv_no", hawbhcvbNo);
        formData.append("airline_prefix_id", (prefix) ? prefix.id : '');
        formData.append("mawb_mcbv_no", mawbmcvbNo);
        formData.append("consignee", (consignee) ? consignee.id : '');
        formData.append("handling_information", handlingInformation);
        formData.append("additional_information", additionalInformation);
        formData.append("ssr_information", ssrInformation);
        formData.append("currency", (currency) ? currency.id : '');
        formData.append("dec_customs", decCustoms);
        formData.append("dec_carriage", decCarriage);

        formData.append("origin_airport", originAirport);
        formData.append("final_destination", finalDestination);
        formData.append("dest_to_1", destTo1);
        formData.append("dest_flight_number_1", destFlightNo1);
        formData.append("dest_flight_date_1", destFlightDate1);
        formData.append("onwd_to_1", onwdTo1);
        formData.append("onwd_flight_number_1", onwdFlightNo1);
        formData.append("onwd_flight_date_1", onwdFlightDate1);
        formData.append("onwd_to_2", onwdTo2);
        formData.append("onwd_flight_number_2", onwdFlightNo2);
        formData.append("onwd_flight_date_2", onwdFlightDate2);

        formData.append("currency_id", (currency) ? currency.id : '');
        formData.append("dec_customs", decCustoms);
        formData.append("acc_weight", accWeight);
        formData.append("due_agent_dha", dueAgent);
        formData.append("payment_method", paymentMethod);
        formData.append("due_carrier", dueCarrier);
        formData.append("mode_of_payment", modeOfPayment);
        formData.append("product_code", productCode);
        formData.append("valuation_charge", valuationCharge);
        formData.append("customs_origin", customsOrigin);
        formData.append("currency_conversion", currencyConvertion);
        formData.append("dec_carriage", decCarriage);

        formData.append("place", signPlace);
        formData.append("date", signDate);
        formData.append("time", signTime);
        formData.append("customs_serial_no", customSerialNo);
        formData.append("sign_of_shipper", signOfShipper);
        formData.append("sign_of_carrier", signOfCarrier);

        formData.append("total_prepaid", totalPrepaid);
        formData.append("total_collect", totalCollect);
        formData.append("grand_total", grandTotal);

        formData.append("weight_unit", weightUnit);
        formData.append("rate_class", rateClass);
        formData.append("iata_rate", iataRate);
        formData.append("total_iata_rate", totalIataRate);
        formData.append("nature_of_goods", natureOfGoods);


        formData.append("other_charge_printing_fields", JSON.stringify(otherChargePrinting));

        formData.append("dims_total_piece", totalDimsPieces);
        formData.append("dims_total_volume", volumeDims);
        formData.append("dims_total_volumetric_weight", volumetricWeightDims);
        formData.append("dims_fileds", JSON.stringify(dimsFormValues));

        formData.append("accounting_codes", JSON.stringify(accFormValues));
        // formData.append("other_charge_fields", JSON.stringify(otherChargeFormValues));

        formData.append("warehouse_loc", (loc) ? loc.id : '');
        formData.append("rack", (rack) ? rack.id : '');
        formData.append("total_packages", totalPackage);
        formData.append("packages_in_date", packageInDate);

        if (airwayBillId) {
            axiosCtx.instance.post(`airway-bill-edit/` + airwayBillId, formData)
                .then((response) => {
                    setLoading(false);
                    history.push({
                        pathname: '/job-details',
                        state: { detail: response.data.data.job, page: 'index', tab: 'air' }
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    setFormError(error.response.data);

                });
        } else {
            axiosCtx.instance.post(`airway-bill`, formData)
                .then((response) => {
                    setLoading(false);
                    history.push({
                        pathname: '/job-details',
                        state: { detail: response.data.data.job, page: 'index', tab: 'air' }
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    setFormError(error.response.data);

                });
        }
    };

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#2f67c9");


    return (
        <>
            <div className="content">
                {loading && loading == true ?
                    <div className="custom-loader">
                        <FadeLoader color={color} loading={loading} css={override} size={150} />
                    </div>
                    : ''
                }
                <Card >
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Airway Bill Form</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col md="4" className="my-4 form-group d-flex">
                                        <Dropdown
                                            id="parties"
                                            placeholder="Shipper"
                                            label="name"
                                            options={parties}
                                            value={shipper}
                                            onChange={(event, newValue) => {
                                                setShipper(newValue);
                                            }}
                                            formError={(formError && formError.errors.shipper) ? formError.errors.shipper : ''}
                                        />

                                    </Col>
                                    <Col md="4" className="my-4 form-group d-flex">
                                        <Dropdown
                                            id="ports"
                                            placeholder="Origin"
                                            label="code"
                                            label1="name"
                                            options={ports}
                                            value={origin}
                                            onChange={(event, newValue) => {
                                                setOrigin(newValue);
                                            }}
                                            formError={(formError && formError.errors.origin) ? formError.errors.origin : ''}
                                        />

                                    </Col>
                                    <Col md="4" className="my-4 form-group">
                                        <Dropdown
                                            id="ports"
                                            placeholder="Destination"
                                            label="code"
                                            label1="name"
                                            options={ports}
                                            value={destination}
                                            onChange={(event, newValue) => {
                                                setDestination(newValue);
                                            }}
                                            formError={(formError && formError.errors.destination) ? formError.errors.destination : ''}
                                        />
                                    </Col>
                                    <Col md="4" className="my-4 form-group">
                                        <InputField
                                            id="Pieces"
                                            placeholder="Pieces"
                                            label="Pieces"
                                            value={piece}
                                            onChange={pieceHandleChange}
                                            formError={(formError && formError.errors.piece) ? formError.errors.piece : ''}
                                        />

                                    </Col>
                                    <Col md="4" className="my-4 form-group d-flex">
                                        <InputField
                                            id="Weight"
                                            placeholder="Weight"
                                            label="Weight"
                                            value={weight}
                                            onChange={weightHandleChange}
                                            formError={(formError && formError.errors.weight) ? formError.errors.weight : ''}
                                        />
                                        <button className="btn btn-simple flex-shrink-0 h-100 my-0" disabled>KG </button>

                                    </Col>
                                    <Col md="4" className="my-4 form-group d-flex">
                                        <InputField
                                            id="Volume"
                                            placeholder="Volume"
                                            label="Volume"
                                            value={volume}
                                            onChange={volumeHandleChange}
                                        />
                                        <button className="btn btn-simple flex-shrink-0 h-100 my-0" disabled>CBM </button>
                                        <button className="btn btn-simple flex-shrink-0 h-100 my-0" onClick={volumeShow}>DIMS </button>

                                    </Col>
                                    <Col md="4" className="my-4 form-group">
                                        <InputField
                                            id="Ntr-of-Goods"
                                            placeholder="Ntr of Goods"
                                            label="Ntr of Goods"
                                            value={ntrOfGoods}
                                            onChange={ntrOfGoodsHandleChange}
                                            formError={(formError && formError.errors.ntr_of_goods) ? formError.errors.ntr_of_goods : ''}
                                        />

                                    </Col>
                                    <Col md="4" className="my-4 form-group">
                                        <InputField
                                            id="Pickup-Loc"
                                            placeholder="Pickup Loc"
                                            label="Pickup Loc"
                                            value={pickupLoc}
                                            onChange={pickupLocHandleChange}
                                            formError={(formError && formError.errors.pickup_loc) ? formError.errors.pickup_loc : ''}
                                        />

                                    </Col>
                                    <Col md="4" className="my-4 form-group d-flex">
                                        <InputField
                                            id="SHC"
                                            placeholder="SHC"
                                            label="SHC"
                                            value={shc}
                                            onChange={shcHandleChange}
                                            formError={(formError && formError.errors.shc) ? formError.errors.shc : ''}
                                        />

                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="4" className="my-4 form-group d-flex">
                                        <InputField
                                            id="HAWB/HCBV No"
                                            placeholder="HAWB/HCBV No"
                                            label="HAWB/HCBV No"
                                            value={hawbhcvbNo}
                                            onChange={hawbNoHandleChange}
                                            formError={(formError && formError.errors.hawb_hcbv_no) ? formError.errors.hawb_hcbv_no : ''}
                                        />

                                    </Col>
                                    <Col md="4" className="my-4 form-group d-flex">
                                        <Dropdown
                                            id="prefixes"
                                            placeholder="Airline Prefix"
                                            label="prefix"
                                            options={prefixes}
                                            value={prefix}
                                            onChange={(event, newValue) => {
                                                setPrefix(newValue);
                                            }}
                                            formError={(formError && formError.errors.airline_prefix_id) ? formError.errors.airline_prefix_id : ''}
                                        />
                                        <InputField
                                            id="MAWB/MCBV No"
                                            placeholder="MAWB/MCBV No"
                                            label="MAWB/MCBV No"
                                            value={mawbmcvbNo}
                                            onChange={mawbNoHandleChange}
                                            formError={(formError && formError.errors.mawb_mcbv_no) ? formError.errors.mawb_mcbv_no : ''}
                                        />
                                    </Col>
                                    <Col md="4" className="my-4 form-group d-flex">
                                        <Dropdown
                                            id="parties"
                                            placeholder="Consignee"
                                            label="name"
                                            options={parties}
                                            value={consignee}
                                            onChange={(event, newValue) => {
                                                setConsignee(newValue);
                                            }}
                                            formError={(formError && formError.errors.consignee) ? formError.errors.consignee : ''}
                                        />
                                    </Col>

                                </Row>
                                <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
                                    WAREHOUSE DETAILS
                                </h6>
                                <Row>
                                    <Col md="3" className="mb-4 form-group d-flex">
                                        <Dropdown
                                            id="locations"
                                            placeholder="Location"
                                            label="name"
                                            options={locations}
                                            value={loc}
                                            onChange={(event, newValue) => {
                                                setLoc(newValue);
                                            }}
                                            formError={(formError && formError.errors.location) ? formError.errors.location : ''}
                                        />

                                    </Col>
                                    <Col md="3" className="mb-4 form-group d-flex">
                                        <Dropdown
                                            id="racks"
                                            placeholder="Rack"
                                            label="name"
                                            options={racks}
                                            value={rack}
                                            onChange={(event, newValue) => {
                                                setRack(newValue);
                                            }}
                                            formError={(formError && formError.errors.rack) ? formError.errors.rack : ''}
                                        />

                                    </Col>

                                    <Col md="3" className="mb-4 form-group d-flex">
                                        <InputDate
                                            id="date"
                                            placeholder="Packages In Date"
                                            label="Packages In Date"
                                            value={packageInDate}
                                            onChange={packageInDateHandleChange}
                                        />

                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <h6 className="font-weight-bold pb-2 ">
                                            Routing Details
                                        </h6>
                                        <div className="border p-3">
                                            <Row>
                                                <Col md="6" className="my-4 form-group d-flex">
                                                    <InputField
                                                        id="Origin Airport"
                                                        placeholder="Origin Airport"
                                                        label="Origin Airport"
                                                        value={originAirport}
                                                        onChange={originAirportHandleChange}
                                                    />
                                                </Col>
                                                <Col md="6" className="my-4 form-group d-flex">
                                                    <InputField
                                                        id="Final Destination"
                                                        placeholder="Final Destination"
                                                        label="Final Destination"
                                                        value={finalDestination}
                                                        onChange={finalDestinationHandleChange}

                                                    />
                                                </Col>

                                            </Row>

                                            <Table className="table border m-0">
                                                <tr className="font-weight-bold">
                                                    <td>Routing</td>
                                                    <td>To</td>
                                                    <td> Flight Number   </td>
                                                    <td> Flight Date    </td>
                                                </tr>
                                                <tr>
                                                    <td >  1st Destination </td>
                                                    <td width="160">   <input type="text" value={destTo1} onChange={destTo1HandleChange} className="form-control" /> </td>
                                                    <td width={200} > <div className="d-flex">
                                                        <input type="text" value={destFlightNo1} onChange={destFlightNo1HandleChange} className="form-control" />
                                                    </div>
                                                    </td>
                                                    <td>
                                                        <InputDate
                                                            id="date"
                                                            placeholder="Date"
                                                            label="Date"
                                                            value={destFlightDate1}
                                                            onChange={destFlightDate1HandleChange}
                                                        />

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td >  1st Onward Dest. </td>
                                                    <td width="160">   <input type="text" value={onwdTo1} onChange={onwdTo1HandleChange} className="form-control" /> </td>
                                                    <td  > <div className="d-flex">
                                                        <input type="text" value={onwdFlightNo1} onChange={onwdFlightNo1HandleChange} className="form-control" />
                                                    </div>
                                                    </td>
                                                    <td>
                                                        <InputDate
                                                            id="date"
                                                            placeholder="Date"
                                                            label="Date"
                                                            value={onwdFlightDate1}
                                                            onChange={onwdFlightDate1HandleChange}
                                                        />

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td >  2nd  Onward Dest. </td>
                                                    <td width="160">   <input type="text" value={onwdTo2} onChange={onwdTo2HandleChange} className="form-control" /> </td>
                                                    <td  > <div className="d-flex">
                                                        <input type="text" value={onwdFlightNo2} onChange={onwdFlightNo2HandleChange} className="form-control" />
                                                    </div>
                                                    </td>
                                                    <td>
                                                        <InputDate
                                                            id="date"
                                                            placeholder="Date"
                                                            label="Date"
                                                            value={onwdFlightDate2}
                                                            onChange={onwdFlightDate2HandleChange}
                                                        />

                                                    </td>
                                                </tr>
                                            </Table> </div>
                                    </Col>
                                    <Col md="6">
                                        <h6 className="font-weight-bold pb-2 mt-4">
                                            Other Details
                                        </h6>
                                        <div className="border p-3">

                                            <Row>
                                                <Col md="12" className="mb-5 form-group d-flex">
                                                    <InputField
                                                        id="Handling Information"
                                                        placeholder="Handling Information"
                                                        label="Handling Information"
                                                        value={handlingInformation}
                                                        onChange={handlingInformationHandleChange}
                                                        formError={(formError && formError.errors.handling_information) ? formError.errors.handling_information : ''}

                                                    />

                                                </Col>
                                                <Col md="12" className="mb-5 form-group d-flex">
                                                    <InputField
                                                        id="Additional Information"
                                                        placeholder="Additional Information"
                                                        label="Additional Information"
                                                        value={additionalInformation}
                                                        onChange={additionalInformationHandleChange}
                                                        formError={(formError && formError.errors.additional_information) ? formError.errors.additional_information : ''}

                                                    />

                                                </Col>
                                                <Col md="12" className=" form-group d-flex">
                                                    <InputField
                                                        id="SSR Information"
                                                        placeholder="SSR Information"
                                                        label="SSR Information"
                                                        value={ssrInformation}
                                                        onChange={ssrInformationHandleChange}
                                                        formError={(formError && formError.errors.ssr_information) ? formError.errors.ssr_information : ''}

                                                    />

                                                </Col>



                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
                                    Valuation Insurance
                                </h6>

                                <Row>
                                    <Col md="4" className="  form-group d-flex">
                                        <Dropdown
                                            id="currencies"
                                            placeholder="Currency"
                                            label="currency_label"
                                            options={currencies}
                                            value={currency}
                                            onChange={(event, newValue) => {
                                                setCurrency(newValue);
                                            }}

                                        />

                                    </Col>
                                    <Col md="4" className="  form-group d-flex">
                                        <InputField
                                            id="Dec. Value For Customs"
                                            placeholder="Dec. Value For Customs"
                                            label="Dec. Value For Customs"
                                            value={decCustoms}
                                            onChange={decCustomsHandleChange}

                                        />
                                    </Col>
                                    <Col md="4" className="  form-group d-flex">
                                        <InputField
                                            id="Dec. Value For Carriage"
                                            placeholder="Dec. Value For Carriage"
                                            label="Dec. Value For Carriage"
                                            value={decCarriage}
                                            onChange={decCarriageHandleChange}

                                        />
                                    </Col>

                                </Row>


                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Accounting Information</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col md="4" className="my-4  form-group d-flex">
                                        <Dropdown
                                            id="currencies"
                                            placeholder="Currency"
                                            label="currency_label"
                                            options={currencies}
                                            value={currency}
                                            onChange={(event, newValue) => {
                                                setCurrency(newValue);
                                            }}
                                            formError={(formError && formError.errors.rack) ? formError.errors.rack : ''}
                                        />

                                    </Col>
                                    <Col md="4" className="my-4  form-group d-flex">
                                        <InputField
                                            id="Dec. Value For Customs"
                                            placeholder="Dec. Value For Customs"
                                            label="Dec. Value For Customs"
                                            value={decCustoms}
                                            onChange={decCustomsHandleChange}

                                        />
                                    </Col>
                                    <Col md="4" className="my-4  form-group d-flex">
                                        <InputField
                                            id="Dec. Value For Carriage"
                                            placeholder="Dec. Value For Carriage"
                                            label="Dec. Value For Carriage"
                                            value={decCarriage}
                                            onChange={decCarriageHandleChange}

                                        />
                                    </Col>

                                    <Col md="4" className="my-4  form-group d-flex">
                                        <FormControl variant="outlined" className="w-100">

                                            <InputLabel size="small" id="weight">Weight/Value</InputLabel>
                                            <Select size="small" labelId="weight" id="select1" value={accWeight} onChange={accWeightHandleChange} label="Weight/Value" >
                                                <MenuItem value="1">Prepaid</MenuItem>
                                                <MenuItem value="2">Charges Collect</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col md="4" className="my-4  form-group d-flex">
                                        <FormControl variant="outlined" className="w-100">
                                            <InputLabel size="small" id="select1">Due Agent & GHA</InputLabel>
                                            <Select size="small" labelId="select1" id="select1" value={dueAgent} onChange={dueAgentHandleChange} label="Due Agent & GHA" >
                                                <MenuItem value="1">Prepaid</MenuItem>
                                                <MenuItem value="2">Charges Collect</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col md="4" className="my-4  form-group d-flex">
                                        <FormControl variant="outlined" className="w-100">
                                            <InputLabel size="small" id="select1">Payment Method</InputLabel>
                                            <Select size="small" labelId="select1" id="select1" value={paymentMethod} onChange={paymentMethodHandleChange} label="Payment Method" >
                                                <MenuItem value="1">Prepaid</MenuItem>
                                                <MenuItem value="2">Charges Collect</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>

                                    <Col md="4" className="my-4  form-group d-flex">
                                        <FormControl variant="outlined" className="w-100">
                                            <InputLabel size="small" id="select1">Due Carrier</InputLabel>
                                            <Select size="small" labelId="select1" id="select1" value={dueCarrier} onChange={dueCarrierHandleChange} label="Payment Method" >
                                                <MenuItem value="1">Prepaid</MenuItem>
                                                <MenuItem value="2">Charges Collect</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>

                                    <Col md="4" className="my-4  form-group d-flex">
                                        <FormControl variant="outlined" className="w-100">
                                            <InputLabel size="small" id="select1">Mode of Payment</InputLabel>
                                            <Select size="small" labelId="select1" id="select1" value={modeOfPayment} onChange={modeOfPaymentHandleChange} label="Payment Method" >
                                                <MenuItem value="1">Prepaid</MenuItem>
                                                <MenuItem value="2">Charges Collect</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col md="4" className="my-4  form-group d-flex">
                                        <InputField
                                            id="Product code"
                                            placeholder="Product code"
                                            label="Product code"
                                            value={productCode}
                                            onChange={productCodeHandleChange}

                                        />
                                    </Col>

                                    <Col md="4" className="my-4  form-group d-flex">
                                        <InputField
                                            id="Valuation Charge"
                                            placeholder="Valuation Charge"
                                            label="Valuation Charge"
                                            value={valuationCharge}
                                            onChange={valuationChargeHandleChange}

                                        />
                                    </Col>

                                    <Col md="4" className="my-4  form-group d-flex">
                                        <InputField
                                            id="Customs Origin"
                                            placeholder="Customs Origin"
                                            label="Customs Origin"
                                            value={customsOrigin}
                                            onChange={customsOriginHandleChange}

                                        />
                                    </Col>

                                    <Col md="4" className="my-4  form-group d-flex">
                                        <InputField
                                            id="Currency Conversion"
                                            placeholder="Currency Conversion"
                                            label="Currency Conversion"
                                            value={currencyConvertion}
                                            onChange={currencyConvertionHandleChange}

                                        />
                                    </Col>
                                    <Col md="12" className="form-group d-flex">
                                        <Button className="mr-3  btn btn-simple" onClick={AWBAccountShow}>More Acc Details</Button>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Weight/Volume & Other Charges</Accordion.Header>
                            <Accordion.Body>
                                <Table className="table border" hover>
                                    <tr className="font-weight-bold">
                                        <td>PCS* </td>
                                        <td>Gross Wt </td>
                                        <td>Kg/Lb </td>
                                        <td>Rate className </td>
                                        <td>Charge Wt* </td>
                                        <td>IATA Rate </td>
                                        <td>Total IATA Rate </td>
                                    </tr>

                                    <tr>
                                        <td> <input name="no_of_piece" type="text" value={piece} className="form-control p-2" /> </td>
                                        <td> <input name="gross_wt" type="text" value={weight} className="form-control p-2" /> </td>
                                        <td>
                                            <select className="custom-select" value={weightUnit} onChange={weightUnitHandleChange} >
                                                <option value="Kg" selected>Kg</option>
                                                <option value="Lb">Lb</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select className="custom-select" value={rateClass} onChange={rateClassHandleChange}>
                                                <option value="Q" selected>Q</option>
                                                <option value="N">N</option>
                                                {/* <option selected>Q</option> */}
                                            </select>
                                        </td>
                                        <td> <input name="charge_wt" type="text" value={volumetricWeightDims} onBlur={calculateTotalIata} className="form-control p-2" /> </td>
                                        <td>   <input name="iata_rate" value={iataRate} onBlur={calculateTotalIata} onChange={iataRateHandleChange} type="text" className="form-control p-2" /> </td>
                                        <td>   <input name="total_iata_rate" value={totalIataRate} type="text" className="form-control p-2" /> </td>
                                        <td>  </td>
                                        {/* <td valign="center">   <DeleteIcon onClick={() => removeOtherChargeField(index)} className="text-danger cursor-p">  </DeleteIcon> </td> */}
                                    </tr>



                                    <tr>
                                        <td colSpan={2}>
                                            <div className="d-flex align-items-center my-3">
                                                <InputField
                                                    id="Total Prepaid"
                                                    placeholder="Total Prepaid"
                                                    label="Total Prepaid"
                                                    value={totalPrepaid}
                                                    onChange={totalPrepaidHandleChange}

                                                />
                                            </div>
                                        </td>
                                        <td colSpan={2}>
                                            <div className="d-flex align-items-center my-3">
                                                <InputField
                                                    id="Total Collect"
                                                    placeholder="Total Collect"
                                                    label="Total Collect"
                                                    value={totalCollect}
                                                    onChange={totalCollectHandleChange}

                                                />
                                            </div>
                                        </td>
                                        <td colSpan={1}>
                                            <div className="d-flex align-items-center my-3">
                                                <InputField
                                                    id="Grand Total"
                                                    placeholder="Grand Total"
                                                    label="Grand Total"
                                                    value={grandTotal}
                                                    onChange={grandleTotalHandleChange}

                                                />
                                            </div>
                                        </td>

                                        <td colSpan={2}>
                                            <InputField
                                                id="nature_of_goods"
                                                placeholder="Nature of Goods"
                                                label="Nature of Goods"
                                                value={natureOfGoods}
                                                onChange={natureOfGoodsHandleChange}
                                                multiline="multiline"
                                                rows={4}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="100%">
                                            <div className="d-flex flex-wrap align-items-center my-3">
                                                <button className="btn btn-simple py-2 btn-sm my-1 mr-2" onClick={otherChargesShow}>Add Other Charges </button>
                                            </div>
                                        </td>
                                    </tr>
                                </Table>
                                <h6 className="font-weight-bold pb-3 mb-4 mt-5 border-bottom">Signature  </h6>
                                <Row>
                                    <Col md="4">
                                        <div className="my-4 d-flex align-items-center">
                                            <InputDate
                                                id="date"
                                                placeholder="Date"
                                                label="Date"
                                                value={signDate}
                                                onChange={signDateHandleChange}
                                            />

                                            <Stack component="form" noValidate spacing={4}>
                                                <TextField
                                                    size="small"
                                                    id="time"
                                                    label="Time"
                                                    type="time"
                                                    defaultValue="2017-05-24"
                                                    value={signTime}
                                                    onChange={signTimeHandleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Stack>
                                        </div>
                                    </Col>

                                </Row>
                                <div className=" w-50 py-4">
                                    <InputField
                                        id="Place"
                                        placeholder="Place"
                                        label="Place"
                                        value={signPlace}
                                        onChange={signPlaceHandleChange}

                                    />
                                </div>
                                <div className=" w-50 py-4">
                                    <InputField
                                        id="Place"
                                        placeholder="Signature of Shipper or his Agent"
                                        label="Signature of Shipper or his Agent"
                                        value={signOfShipper}
                                        onChange={signOfShipperHandleChange}

                                    />
                                </div>

                                <div className=" w-50 py-4">
                                    <InputField
                                        id="Place"
                                        placeholder="Signature of Issuing Carrier or Its Agent "
                                        label="Signature of Issuing Carrier or Its Agent "
                                        value={signOfCarrier}
                                        onChange={signOfCarrierHandleChange}

                                    />
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <button onClick={submitHandler} className="btn btn-primary ml-3">Save</button>
                    </Accordion>

                    <Modal show={ShowVolume} onHide={volumeClose} dialogClassName="modal-90w" centered>
                        <Modal.Header className="p-4">
                            <Modal.Title className="m-0">Volume – DIMS  </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            <Table className="table" striped bordered hover>
                                <tr className="font-weight-bold">
                                    <td>    </td>
                                    <td> No. Pcs </td>
                                    <td width="200"> Units </td>
                                    <td> Length </td>
                                    <td> Width </td>
                                    <td> Height </td>
                                    <td> Volume(CBM) </td>
                                    <td> Volumetric Weight </td>
                                </tr>

                                {dimsFormValues.map((element, index) => (
                                    <tr>
                                        <td>  <input type="checkbox" /> </td>
                                        <td>  <input type="number" name="piece" className="form-control" value={element.piece} onChange={e => dimsFieldsHandleChange(index, e)} onBlur={e => dimsFieldsFocusOut(index, e)} /> </td>
                                        <td>  <select className="custom-select" >
                                            <option selected>Centimeters</option>
                                        </select>
                                        </td>
                                        <td>   <input type="number" name="length" className="form-control" value={element.length} onChange={e => dimsFieldsHandleChange(index, e)} onBlur={e => dimsFieldsFocusOut(index, e)} /> </td>
                                        <td>   <input type="number" name="width" className="form-control" value={element.width} onChange={e => dimsFieldsHandleChange(index, e)} onBlur={e => dimsFieldsFocusOut(index, e)} /> </td>
                                        <td>   <input type="number" name="height" className="form-control" value={element.height} onChange={e => dimsFieldsHandleChange(index, e)} onBlur={e => dimsFieldsFocusOut(index, e)} /> </td>
                                        <td>   <input type="number" name="volume" className="form-control" value={element.volume} onChange={() => { }} /> </td>
                                        <td>   <input type="number" name="volumetric_weight" className="form-control" value={element.volumetric_weight} onChange={() => { }} /> </td>
                                        <td width="50">
                                            <DeleteIcon
                                                onClick={e => removeDimsField(index, e)}
                                                className="text-danger cursor-p">
                                            </DeleteIcon>
                                        </td>
                                    </tr>
                                ))}

                                <tr className="font-weight-bold">
                                    <td>    </td>
                                    <td> <input type="text" className="form-control" value={totalDimsPieces} /> </td>
                                    <td width="200"> </td>
                                    <td> </td>
                                    <td>  </td>
                                    <td>  </td>
                                    <td> <input type="text" className="form-control" value={volumeDims} /> </td>
                                    <td> <input type="text" className="form-control" value={volumetricWeightDims} /> </td>
                                </tr>

                            </Table>
                        </Modal.Body>
                        <Modal.Footer className="p-4">

                            <Button className="mr-3"
                                variant="secondary"
                                onClick={addDimsField}>
                                Add
                            </Button>
                            <Button variant="primary" onClick={volumeClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={ShowAWBAccount} onHide={AWBAccountClose}  >
                        <Modal.Header className="px-4">
                            <Modal.Title className="m-0">AWB Account Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            <Row>
                                <Col md="4" className="my-2  font-weight-bold" >Accounting Code  </Col>
                                <Col className="my-2  font-weight-bold">Accounting  </Col>
                            </Row>

                            {accFormValues.map((element, index) => (
                                <Row>
                                    <Col md="4" className="my-2  form-group d-flex">
                                        <input size="small" type="text" value={element.code} className="form-control" onClick={() => accountCodeShow(index)} />
                                    </Col>
                                    <Col className="my-2  form-group d-flex">
                                        <input size="small" type="text" name="description" value={element.description} onChange={e => onAccDescChange(index, e)} className="form-control" />
                                    </Col>
                                    <Col md="1" className="my-2 form-group d-flex">
                                        <DeleteIcon onClick={() => removeAccField(index)} className="text-danger cursor-p">  </DeleteIcon>
                                    </Col>
                                </Row>
                            ))}

                            <button type="button" onClick={addAccField} className="mr-3 w-100 btn btn-simple btn btn-primary">Add</button>

                        </Modal.Body>
                        <Modal.Footer className="px-4">
                            <Button
                                className="mr-3"
                                variant="secondary"
                                onClick={AWBAccountClose}
                            >
                                Cancel
                            </Button>

                            <Button variant="primary" onClick={AWBAccountClose}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={showAccountCode} onHide={accountCodeClose} size="lg" >
                        <Modal.Header className="px-4">
                            <Modal.Title className="m-0">Codes</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">

                            <div className="d-flex w-75 mb-4">
                                <select className="custom-select h-100  p-2" >
                                    <option selected>Select search field</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <input type="text" className="form-control mx-2" />
                                <button className="btn btn-primary m-0">Search</button>
                            </div>

                            <Table className="table" striped bordered hover>
                                <tr className="font-weight-bold">
                                    <td width="10">    </td>
                                    <td> Code </td>
                                    <td> Code Value</td>
                                    <td> Description </td>
                                </tr>

                                {codes.map((element, index) => (
                                    <tr onClick={() => onClickCode(index)}>
                                        <td>  <input type="radio" /> </td>
                                        <td>  {element.code}  </td>
                                        <td>  {element.description}   </td>
                                        <td>  ACCTINFO  </td>
                                    </tr>

                                ))}

                            </Table>
                        </Modal.Body>
                        <Modal.Footer className="px-4">
                            <Button
                                className="mr-3"
                                variant="secondary"
                                onClick={accountCodeClose}
                            >
                                Close
                            </Button>


                        </Modal.Footer>
                    </Modal>

                    <Modal show={ShowOtherCharges} onHide={otherChargesClose} size="lg" >
                        <Modal.Header className="px-4">
                            <Modal.Title className="m-0">Additional Charges</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">

                            <Table className="table" striped bordered hover>
                                <tr className="font-weight-bold">
                                    <td>Charge Code</td>
                                    <td>Payment Mode</td>
                                    <td>Agent/Airline</td>
                                    <td>Amount</td>
                                </tr>

                                {otherChargePrinting && otherChargePrinting.map((element, index) => (
                                    <tr>
                                        <td>   <input type="text" name="charge_code" value={element.charge_code} onChange={e => onOtherChargePrintingChange(index, e)} className="form-control" /> </td>
                                        <td>
                                            <select className="custom-select" name="payment_mode" value={element.payment_mode} onChange={e => onOtherChargePrintingChange(index, e)} >
                                                <option value="">none</option>
                                                <option value="1">Prepaid</option>
                                                <option value="2">Charges Collect</option>
                                            </select> </td>
                                        <td>
                                            <select className="custom-select" name="agent_airline" value={element.agent_airline} onChange={e => onOtherChargePrintingChange(index, e)} >
                                                <option value="">none </option>
                                                <option value="1">Due Agent </option>
                                                <option value="2">Due Carrier </option>
                                                <option value="3">Due GHA</option>
                                            </select> </td>
                                        <td>  <input type="text" className="form-control" name="amount" value={element.amount} onChange={e => onOtherChargePrintingChange(index, e)} /> </td>
                                        <td>  <DeleteIcon onClick={() => removeOtherChargePrinting(index)} className="text-danger cursor-p"> </DeleteIcon> </td>
                                    </tr>
                                ))}
                            </Table>
                            <button type="button" onClick={addOtherChargePrinting} className="mr-3  btn btn-simple btn btn-primary">Add</button>
                        </Modal.Body>
                        <Modal.Footer className="px-4">
                            <Button
                                className="mr-3"
                                variant="secondary"
                                onClick={otherChargesClose}
                            >
                                Cancel
                            </Button>

                            <Button variant="primary" onClick={otherChargesClose}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </div>
        </>
    );
}

export default AirwayBillDetail;
