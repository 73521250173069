import AirwayBillDetail from 'components/AirwayBillDetail';
import Wrapper from 'components/Wrapper';

function AirwayBillDetailPage() {
  return (
    <Wrapper>
      <AirwayBillDetail />
    </Wrapper>
  );
}

export default AirwayBillDetailPage;
