import InvoicePdf from 'components/Pdf/InvoicePdf';
import Wrapper from 'components/Wrapper';

function InvoicePdfPage() {
  return (
    <Wrapper>
      <InvoicePdf />
    </Wrapper>
  );
}

export default InvoicePdfPage;
