import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import logo from "assets/img/print-logo-invoice.png";
import AxiosContext from '../../store/axios-context';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";


const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "calc(100vh - 94px)",
  },
  page: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    flexDirection: 'column',
    position: "relative",

  },
  header: {
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col6: {
    width: '50%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  col7: {
    width: '65%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  col5: {
    width: '35%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },

  bold: {
    fontWeight: '600',
    fontSize: 12,
  },
  dflex: {
    display: 'flex',
  },

  thcol: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginBottom: -1,
    marginRight: -1,
  },
  tdcol: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginBottom: -1,
    marginRight: -1,
  },
  description: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: '40%'
  },

  thHeading: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
  },

  total: {
    borderWidth: 1,
    borderColor: '#fff',
    borderBottomWidth: 1,
    padding: 5,
    marginTop: 1,
    marginRight: -1,
    width: '50%',
    textAlign: 'right'
  },
  tdBorderless: {
    borderWidth: 1,
    borderColor: '#fff',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginTop: 1,
    marginRight: -1,
  },

  marginY5: {
    marginTop: 25,
    marginBottom: 25
  },


  border: {
    borderWidth: 1,
    borderColor: '#999',
    paddingRight: 1,
    marginRight: -1,
    paddingBottom: 2
  },

  withoutBorder: {
    marginLeft: 25,
    textAlign: 'center',
    width: 160,
    fontSize: 8,
  },
  padding10: {
    padding: 10,
  },
  w100: {
    width: '100%',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  footer:{
  position: 'absolute',
  bottom:0,
  left: 30,
  right: 30,
  }

});

function InvoicePdf() {

  const location = useLocation();
  const history = useHistory();
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const [selectedParty, setSelectedParty] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [mawb, setMawb] = useState('');
  const [prefix, setPrefix] = useState('');
  const [destination, setDestination] = useState('');
  const [ourReference, setOurReference] = useState('');
  const [origin, setOrigin] = useState('');
  const [inFrom, setInFrom] = useState('');

  const [totalPkg, setTotalPkg] = useState(0);
  const [grossWt, setGrossWt] = useState(0);
  const [chargeWt, setChargeWt] = useState(0);

  const [invoiceDate, setInvoiceDate] = React.useState('');
  const [invoiceCurrency, setInvoiceCurrency] = React.useState('');
  const [costs, setCosts] = useState(null);
  const [conversionRate, setConversionRate] = useState([]);

  var converter = require('number-to-words');

  const capitalizeString = (str) => {

    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");

    return str2;

  }

  const { id } = useParams();

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }

    const invoiceRequest = axiosCtx.instance.get(`invoice/${id}`);
    const costsRequest = axiosCtx.instance.get(`cost/invoice/${id}`);

    Promise.all([costsRequest, invoiceRequest]).then(([costsResponse, invoicesResponse]) => {

      if (costsResponse.status === 200) {
        setCosts(() => costsResponse.data.data === undefined ? [] : costsResponse.data.data);
      }

      if (invoicesResponse.status === 200) {
        let response = invoicesResponse.data.data;
        if (response.conversion_value) {
          setConversionRate(JSON.parse(response.conversion_value));
        }

        setInvoiceNumber(response.invoice_number);
        setOurReference(response.our_reference);
        setSelectedParty(response.party);
        setSelectedJob(response.job);
        setSelectedProject(response.project);
        setInvoiceCurrency(response.currency.currency_label);
        setInvoiceDate(response.invoice_date);

        setPrefix((response.airwaybill) ? response.airwaybill.prefix.prefix : '');
        setMawb((response.airwaybill) ? response.airwaybill.mawb_mcbv_no : '');
        if (response.destination != null) {
          setDestination(response.destination.name);
        }
        setInFrom(response.in_from);
        setTotalPkg(response.total_package);
        setGrossWt(response.gross_wt);
        setChargeWt(response.charge_wt);
      }

    }).catch((error) => {
      // TODO: Handle Error
      console.log(error)
      // alert('Sorry, something went wrong!');
    });

  }, []);

  let totalAmountBeforeVat = 0;
  let totalVatAmount = 0;
  let totalAmountAfterVat = 0;
  let amountInWords = '';

  let con_cost_amount = 0;
  let con_cost_total = 0;
  let con_revenue_amount = 0;
  let con_revenue_total = 0;
  let fraction = 0;
  return (
    <>
      <div className="content pdfViewer">
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.header}>
                <Image style={styles.logo} src={logo} />
              </View>

              <View>
                <View style={styles.row} >
                  <Text style={[styles.thcol, styles.bold, { fontSize: 13, textAlign: 'center' }]}>TAX INVOICE</Text>
                </View>

                <View style={styles.row} >
                  <View style={[styles.thcol, { minWidth: '20%', lineHeight: '1.5px' }]}>
                    <Text style={styles.w100}> CUSTOMER NAME & ADDRESS </Text>
                    <Text style={[styles.w100, styles.bold]}> {selectedParty.name} </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.address_1}   </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.address_2} </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.city} </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.country} </Text>
                  </View>
                  <View style={[styles.tdcol, { padding: 0, borderLeftColor: '#fff' }]}>
                    <View style={[styles.row]} >
                      <Text style={styles.thcol}> INVOICE NO: </Text>
                      <Text style={styles.thcol}>{invoiceNumber ?? ''} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>DATE  </Text>
                      <Text style={styles.thcol}>{invoiceDate ?? ''} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>FROM  </Text>
                      <Text style={styles.thcol}>{inFrom} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>TO  </Text>
                      <Text style={styles.thcol}>{destination} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>MAWB NO  </Text>
                      <Text style={styles.thcol}>{prefix}{mawb} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>JOB REF  </Text>
                      <Text style={styles.thcol}>{selectedJob.job_id} </Text>
                    </View>

                  </View>
                </View>

                <View style={styles.row} >
                  <Text style={styles.thcol}>Arrival Date.  </Text>
                  <Text style={styles.thcol}>Ch. wt. (Kgs)  </Text>
                  <Text style={styles.thcol}>Gross Wt. </Text>
                  <Text style={styles.thcol}>No of Pkgs </Text>
                  <Text style={[styles.thcol, { minWidth: 146.5 }]}>TAX REGISTRATION NO </Text>
                </View>
                <View style={styles.row} >
                  <Text style={styles.thcol}>    </Text>
                  <Text style={styles.thcol}> {(chargeWt > grossWt) ? chargeWt : grossWt}   </Text>
                  <Text style={styles.thcol}>  {grossWt}  </Text>
                  <Text style={styles.thcol}>  {totalPkg}  </Text>
                  <Text style={[styles.thcol, { minWidth: 146.5 }]}> 100400007900003  </Text>
                </View>
                <View style={styles.row} >
                  <Text style={styles.thcol}>  Our Reference : {ourReference}  </Text>
                </View>
                <View style={styles.row} >
                  <Text style={styles.thcol}>  Project : {selectedProject.name} </Text>
                </View>

                <View style={styles.row} >
                  <Text style={styles.description}> DESCRIPTION  </Text>
                  <Text style={styles.thcol}>  Unit  </Text>
                  <Text style={styles.thcol}>  Amount {"\n"} ({invoiceCurrency})  </Text>
                  <Text style={styles.thcol}> VAT (%)  </Text>
                  <Text style={styles.thcol}>Gross Amount ({invoiceCurrency}) </Text>
                </View>
                <View style={styles.border} >
                  {
                    costs !== null && !costs.length && (
                      <h6 className="text-danger">Sorry, No Costs found !</h6>
                    )
                  }
                  {
                    costs && costs.map((element, index) => {
                      return (
                        <>
                          <View style={styles.row} >
                            <Text style={[styles.tdcol, styles.bold, { borderColor: '#fff' }]}>{element.order_no} </Text>
                          </View>
                          {
                            element.costs && element.costs.map((costDetail, i) => {

                              if (costDetail.revenue_currency != null && costDetail.revenue_total != null && costDetail.revenue_total > 0) {

                                if (conversionRate[costDetail.revenue_currency.currency_label]) {
                                  con_revenue_amount = costDetail.revenue_amount * conversionRate[costDetail.revenue_currency.currency_label];
                                  con_revenue_total = costDetail.revenue_total * conversionRate[costDetail.revenue_currency.currency_label];
                                } else {
                                  con_revenue_amount = costDetail.revenue_amount;
                                  con_revenue_total = costDetail.revenue_total;

                                }

                                let amount = parseFloat(con_revenue_amount).toFixed(2);
                                let totalAmount = parseFloat(con_revenue_total).toFixed(2);
                                let vatAmount = amount * (costDetail.revenue_vat / 100);
                                let vatPercentage = costDetail.revenue_vat;

                                totalAmountBeforeVat = (parseFloat(totalAmountBeforeVat) + parseFloat(amount));
                                totalAmountAfterVat = (parseFloat(totalAmountAfterVat) + parseFloat(totalAmount));
                                totalVatAmount = (parseFloat(totalVatAmount) + parseFloat(vatAmount));

                                let whole = Math.floor(totalAmountAfterVat);
                                fraction = totalAmountAfterVat - parseFloat(whole);

                                // let amount = parseFloat(costDetail.cost_amount + costDetail.revenue_amount).toFixed(2);
                                // let totalAmount = parseFloat(costDetail.cost_total + costDetail.revenue_total).toFixed(2);
                                // let vatAmount = parseFloat(totalAmount - amount).toFixed(2);
                                // let vatPercentage = parseFloat(vatAmount / amount).toFixed(2);

                                // totalAmountBeforeVat = (parseFloat(totalAmountBeforeVat) + parseFloat(amount));
                                // totalAmountAfterVat = (parseFloat(totalAmountAfterVat) + parseFloat(totalAmount));
                                // totalVatAmount = (parseFloat(totalVatAmount) + parseFloat(vatAmount));


                                return (
                                  <View style={styles.row} >
                                    <Text style={[styles.description, { borderColor: '#fff' }]}> {costDetail.cost_description.name}  </Text>
                                    <Text style={[styles.tdcol, { textAlign: 'right', borderColor: '#fff' }]}> {costDetail.cost_unit} </Text>
                                    <Text style={[styles.tdcol, { textAlign: 'right', borderColor: '#fff' }]}> {amount} </Text>
                                    <Text style={[styles.tdcol, { textAlign: 'right', borderColor: '#fff' }]}> {vatPercentage}  </Text>
                                    <Text style={[styles.tdcol, { textAlign: 'right', borderColor: '#fff' }]}>{totalAmount} </Text>
                                  </View>

                                );
                              }
                            })


                          }
                        </>
                      );
                    })
                  }

                </View>
                <View style={[styles.row, styles.bold, { fontSize: '12' }]} >
                  <Text style={[styles.tdcol, { borderRightColor: '#fff' }]}>TOTAL </Text>
                  <Text style={[styles.tdcol, { textAlign: 'right', borderLeftColor: '#fff', paddingRight: 4 }]}>{invoiceCurrency} {parseFloat(totalAmountAfterVat).toFixed(2)}</Text>
                </View>

                <Text style={[{ paddingTop: 5 }]}>TOTAL :  {capitalizeString(converter.toWords(parseFloat(totalAmountAfterVat).toFixed(2)))} {(fraction != 0) ? 'and Cents '+ fraction.toFixed(2) * 100 +'/00 Only' : 'Only' }    </Text>

                <Text style={[{ paddingBottom: 5, paddingTop: 5, textAlign: 'center', fontSize: 8,  }]}>This is a system generated report which does not require any signature.Any discrepancy should be reported within 7 days from the date of invoice else this will be considered as final.</Text>
                </View>

                <View style={[styles.footer,{  borderColor: '#000', borderTopWidth: 1, }]} >
                <Text style={[styles.bold, { fontSize: '10', paddingBottom: 5, paddingTop: 5 }]}>Bank Details : </Text>
                <Text style={[styles.bold, { color: '#1f497d' }]}>Sea Trust Shipping Services LLC </Text>
                <Text style={[styles.bold, { color: '#1f497d' }]}>RAK BANK: USD A/C # 0242242854002</Text>

                <Text style={[styles.bold, { fontSize: '9', paddingBottom: 10, paddingTop: 10, textAlign: 'center' }]}>Office No 2024, ACT Building, Dubai Cargo Village, Dubai , UAE .  PO BOX 182693 Tel : +971-4-2822180 Fax : +971-4-2869455 , E-mail : info@seatrustshipping.com, Web: www.seatrustshipping.com</Text>

              </View>

            </Page>
          </Document>
        </PDFViewer>
      </div>
    </>
  );
}

export default InvoicePdf;
