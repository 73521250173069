import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import AxiosContext from '../../store/axios-context';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const styles = StyleSheet.create({
    viewer: {
        width: "100%",
        height: "calc(100vh - 94px)",
    },
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 90,
        paddingLeft: 38,
        paddingRight: 30,
        flexDirection: 'column',
        position: 'relative',

    },
    bgImg: {
        top: 8,
        left: 5,
        right: 0,
        position: 'absolute'
    },
    header: {
        marginBottom: 20,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    col6: {
        width: '50%',
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10
    },
    col7: {
        width: '65%',
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10
    },
    col5: {
        width: '35%',
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10
    },

    bold: {
        fontWeight: '600',
        fontSize: 12,
    },
    dflex: {
        display: 'flex',
    },

    thcol: {
        padding: 4,
        flex: '1 1 auto',

    },
    tdcol: {
        padding: 4,
        flex: '1 1 auto',

    },
    description: {
        borderWidth: 1,
        borderColor: '#999',
        borderBottomWidth: 1,
        padding: 5,
        marginBottom: -1,
        marginRight: -1,
        width: '40%'
    },

    thHeading: {
        borderWidth: 1,
        borderColor: '#999',
        borderBottomWidth: 1,
        padding: 5,
        marginBottom: -1,
        marginRight: -1,
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
    },

    total: {
        borderWidth: 1,
        borderColor: '#fff',
        borderBottomWidth: 1,
        padding: 5,
        marginTop: 1,
        marginRight: -1,
        width: '50%',
        textAlign: 'right'
    },
    tdBorderless: {
        borderWidth: 1,
        borderColor: '#fff',
        borderBottomWidth: 1,
        padding: 5,
        flex: '1 1 auto',
        marginTop: 1,
        marginRight: -1,
    },

    marginY5: {
        marginTop: 25,
        marginBottom: 25
    },


    border: {

    },

    withoutBorder: {
        marginLeft: 25,
        textAlign: 'center',
        width: 160,
        fontSize: 8,
    },
    padding10: {
        padding: 10,
    },
    w100: {
        width: '100%',
    },
    bold: {
        fontFamily: 'Helvetica-Bold',
    },
    marginB: {
        marginBottom: 10
    }

});

function ExitCertificatePdf() {

    const location = useLocation();
    const history = useHistory();
    const axiosCtx = useContext(AxiosContext);
    const authCtx = useContext(AuthContext);

    const [exitFormValues, setExitFormValues] = useState([{ quantity: 0, description: "", value: "" }])

    const [exitTotalQuantity, setExitTotalQuantity] = useState(0);
    const [exitWeight, setExitWeight] = useState(0);
    const [exitWeightUnit, setExitWeightUnit] = useState(0);
    const [exitDestination, setExitDestination] = useState('');
    const [exitId, setExitId] = useState(0);

    const [exitAgent, setExitAgent] = useState('');
    const [exitTransitBillNo, setExitTransitBillNo] = useState('');
    const [exitTruckDetails, setExitTruckDetails] = useState('');
    const [exitOrigin, setExitOrigin] = useState('');
    const [exitDate, setExitDate] = useState('');
    const [exitAirwayBillNo, setExitAirwayBillNo] = useState('');
    const [exitPrefix, setExitPrefix] = useState('');
    const [exitPoe, setExitPoe] = useState('');

    const { id } = useParams();

    // const exitAgentHandleChange = (event) => {
    //     setExitAgent(event.target.value);
    // };

    // const exitTransitBillNoHandleChange = (event) => {
    //     setExitTransitBillNo(event.target.value);
    // };

    // const exitOriginHandleChange = (event) => {
    //     setExitOrigin(event.target.value);
    // };

    // const exitDateHandleChange = (event) => {
    //     setExitDate(event.target.value);
    // };

    // const exitAirwayBillNoHandleChange = (event) => {
    //     setExitAirwayBillNo(event.target.value);
    // };

    // const exitPoeHandleChange = (event) => {
    //     setExitPoe(event.target.value);
    // };

    useEffect(() => {
        if (authCtx.isLoggedIn == false) {
            history.replace('/auth/Login');
        }
        const exitCertificateRequest = axiosCtx.instance.get(`exit-certificate/${id}`);
        Promise.all([exitCertificateRequest]).then(([exitCertificateResponse]) => {

            if (exitCertificateResponse.status === 200) {
                let response = exitCertificateResponse.data.data;

                setExitAgent((response.ships_agent) ? response.ships_agent.name : '');
                setExitTransitBillNo(response.transit_bill_no);
                setExitOrigin(response.origin);
                setExitDate(response.date);
                setExitAirwayBillNo(response.airway_bill_no.mawb_mcbv_no);
                setExitPrefix(response.prefix.prefix);
                setExitPoe(response.point_of_exit.name);
                setExitTotalQuantity(response.total_quantity);
                setExitWeight(response.weight);
                setExitWeightUnit(response.airway_bill_no.weight_unit);
                setExitId(response.id);
                setExitDestination(response.destination.name);
                setExitTruckDetails(response.truck_details);
                setExitFormValues(JSON.parse(response.dynamic_fields));

            }

        }).catch((error) => {
            // TODO: Handle Error
            console.log(error)
            alert('Sorry, something went wrong!');
        });
    }, []);

    return (
        <>
            <div className="content pdfViewer">
                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page}>
                            {/* <View style={styles.bgImg} >
                            <Image style={styles.bgImage} src={bgImage} />
                        </View> */}
                            <View>
                                <View style={styles.row} >
                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff' }]}>
                                    </View>
                                    <View style={[styles.tdcol, { padding: 0, minWidth: 80, borderColor: '#fff' }]}>
                                        <View style={[styles.border, styles.bold, { padding: 10, textAlign: "center" }]}>

                                        </View>

                                    </View>
                                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", borderColor: '#fff' }]}>
                                        <Text style={[styles.border, styles.bold, { padding: 10, marginLeft: 20, marginTop: 8, maxWidth: 100 }]}>    </Text>

                                    </View>
                                </View>





                                <View style={[styles.row]} >
                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', minWidth: 300 }]}>

                                        <Text style={[styles.bold, { padding: 10, marginTop: 25, marginRight: 50, marginLeft: 40 }]}> {exitAgent ?? ''}   </Text>
                                    </View>

                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff' }]}>

                                        {/* <Text style={[styles.border, { padding: 10, marginTop: 15, maxWidth: 150 }]}>  {exitDate ?? ''}  </Text> */}
                                    </View>
                                </View>


                                <View style={[styles.row, styles.marginB]} >
                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', minWidth: 100 }]}>

                                        <Text style={[styles.bold, { padding: 10, marginTop: 22, marginLeft: 55 }]}> {exitTransitBillNo ?? ''}   </Text>
                                    </View>

                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', }]}>

                                        <Text style={[styles.bold, , { padding: 10, marginTop: 23, marginLeft: 10 }]}> {exitDate ?? ''}   </Text>
                                    </View>

                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff' }]}>

                                        <Text style={[styles.bold, { paddingTop: 10, marginTop: 25, }]}> {exitPrefix}{exitAirwayBillNo}   </Text>
                                    </View>
                                </View>

                                <View style={[styles.row, styles.marginB]} >
                                    <View style={[styles.tdcol, { padding: 0, width: 100 }]}>

                                        <Text style={[styles.bold, { paddingLeft: 90, marginTop: 14, marginRight: 100 }]}> {exitOrigin}   </Text>
                                    </View>

                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff' }]}>

                                        <Text style={[styles.bold, { paddingLeft: 95, marginTop: 14, }]}> {exitPoe}   </Text>
                                    </View>
                                </View>



                                <View style={[styles.bold, { marginTop: 42, height: 150 }]} >

                                    {exitFormValues && exitFormValues.map((element, index) => (
                                        <View style={[styles.row, {}]} >
                                            <Text style={[styles.tdcol, { maxWidth: 85, paddingLeft: 15, borderTopColor: '#fff', borderBottomColor: '#fff', borderLeftColor: '#fff' }]}> {element.quantity ?? ''} PKG </Text>
                                            <View style={[styles.tdcol, { borderTopColor: '#fff', borderBottomColor: '#fff', }]}>
                                                <Text> {element.description ?? ''} </Text>
                                                {/* <Text  style={[  { marginTop: 42,  }]}> {element.description ?? ''} </Text> */}
                                            </View>
                                            <Text style={[styles.tdcol, { borderTopColor: '#fff', borderBottomColor: '#fff' }]}> {element.value ?? ''} </Text>
                                        </View>
                                    ))}
                                    <Text style={[{ marginTop: 39, paddingLeft: 89 }]}> TRUCK NO: {exitTruckDetails} </Text>



                                </View>




                                <View style={[styles.row, { paddingTop: 7, }]} >
                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', width: 100 }]}>

                                        <Text style={[styles.bold, { padding: 10, marginTop: 8, marginRight: 30, paddingLeft: 40 }]}> {exitTotalQuantity}   PKG </Text>
                                    </View>

                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', }]}>
                                        <Text style={[styles.bold, { paddingLeft: 45, marginTop: 20, }]}> {exitWeight} {exitWeightUnit ? exitWeightUnit.toUpperCase() : ''}   </Text>
                                    </View>

                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff' }]}>

                                        <Text style={[styles.bold, { paddingLeft: 35, marginTop: 20, }]}> {exitDestination}   </Text>
                                    </View>
                                </View>


                                {/* <View style={[styles.row, styles.marginB]} >
                                   <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', minWidth: 100, }]}>
                                        <View style={[styles.border, { padding: 10, marginTop: 8, marginRight: 20 }]}>
                                            

                                            <View style={[styles.row, { marginTop: 60, fontSize: 9 }]} >
                                                <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', minWidth: 100 }]}>
                                                    <Text style={[, { marginTop: 5, }]}>   (Arabic)</Text>
                                                    <Text style={[, { marginTop: 5, }]}> Receipt or ine goods Stamp & Signature </Text>

                                                </View>
                                                <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', minWidth: 100 }]}>
                                                    <Text style={[, { marginTop: 5, }]}>   (Arabic)</Text>
                                                    <Text style={[, { marginTop: 5, }]}>Vessel's Stamp, Master's Signature</Text>
                                                    <Text style={[, { marginTop: 5, }]}>Date  (Arabic)</Text>
                                                </View>
                                            </View>


                                        </View>
                                    </View> 
                                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', }]}>
                                        <View style={[styles.border, { padding: 10, marginTop: 8, }]}>
                                            <Text style={[, { marginTop: 5, }]}> We certificate that the GLeglall gl agai
                                                details given by us ON (je LikaCy ollaal!
                                                this document are correct.  (Arabic)</Text>

                                            <View style={[{ marginTop: 50, fontSize: 9 }]} >
                                                <Text style={[, { marginTop: 5, }]}> Stamp & Signature   (Arabic)</Text>
                                                <Text style={[, { marginTop: 5, }]}> Ship Agent  (Arabic)</Text>
                                            </View>


                                        </View>
                                    </View>
                                </View>


                                <View style={[styles.row, {}]} >
                                    <View style={[styles.tdcol, { borderTopColor: '#fff', borderLeftColor: '#fff', borderRightColor: '#fff', }]}>

                                        <Text style={[, { marginTop: 5, }]}> he Ship's Agent needs this certificate that these goods EI cle Galesdgl Balgill oc oi] 3 SL Sy cling
                                            have left the United Arab Emirates, arrived in a foreign country, col Lelpearyshe of cil Ley Alga Cpa o el 3 9S dc Laut! .
                                            2. or been received on an ocean going vessel, so that ASSy callayreall 3 yrLetl Casall jlelge Maal of turaltty iF
                                            oS Deposit paid may be refunded by Dubai Customs. </Text>

                                    </View>
                                    <View style={[styles.tdcol, { borderTopColor: '#fff', borderLeftColor: '#fff', borderRightColor: '#fff', }]}>
                                        <Text style={[, { marginTop: 5, marginLeft: 20 }]}>   (Arabic)</Text>

                                    </View>
                                </View>



                                <View style={[styles.row, {}]} >
                                    <View style={[styles.tdcol, { borderLeftColor: '#fff', borderRightColor: '#fff', }]}>
                                        <Text style={[, { marginTop: 5, }]}>   (Arabic)</Text>

                                        <Text style={[, { marginTop: 5, }]}> This Certificate must be prepared in typescript.</Text>

                                    </View>
                                    <View style={[styles.tdcol, { borderLeftColor: '#fff', borderRightColor: '#fff', }]}>
                                        <Text style={[, { marginTop: 5, }]}>   (Arabic)</Text>
                                        <Text style={[, { marginTop: 5, }]}>  Any alteration will invalidate this certificate</Text>

                                    </View>
                                </View> */}




                            </View>

                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </>
    );
}

export default ExitCertificatePdf;
