import React, { useContext, useEffect, useState } from "react";
import { Accordion } from 'react-bootstrap'
import { useParams } from "react-router-dom";
// reactstrap components
import {
    Card,
    Table,
    Row,
    Col,
} from "reactstrap";
import FormControl from '@material-ui/core/FormControl';
import AxiosContext from '../store/axios-context';
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import InputField from "./Fields/InputField";
import InputDate from "./Fields/InputDate";
import Dropdown from "./Fields/Dropdown";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function InvoiceEdit(props) {

    const history = useHistory();
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [invoices, setInvoices] = useState([]);
    const axiosCtx = useContext(AxiosContext);
    const authCtx = useContext(AuthContext);
    const [formError, setFormError] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        if (authCtx.isLoggedIn == false) {
            history.replace('/auth/Login');
        }
        getJobs();
        getParties();
        getProjects();
        getCurrency();
        getStatues();
        getJobtypes();
        getInvoiceById(id);
    }, []);
    
    const getInvoiceById = (id) => {
        setLoading(true);

        const invoiceRequest = axiosCtx.instance.get(`invoice/${id}`);
        const costsRequest = axiosCtx.instance.get(`cost/invoice/${id}`);

        Promise.all([costsRequest, invoiceRequest]).then(([costsResponse, invoicesResponse]) => {

            if (costsResponse.status === 200) {
                setCosts(() => costsResponse.data.data === undefined ? [] : costsResponse.data.data);
            }

            if (invoicesResponse.status === 200) {
                let response = invoicesResponse.data.data;
                if (response.conversion_value) {
                    setConversionRate(JSON.parse(response.conversion_value));
                }
                setInvoiceId(response.id);
                setSelectedParty(response.party);
                setSelectedJob(response.job);
                setSelectedProject(response.project);
                setInvoiceCurrency(response.currency);
                setInvoiceDate(response.invoice_date);
                // setDestination(response.destination.id);
                setOurReference(response.our_reference);
                setReceivedAmount(response.received_amount);
                setPaymentReference(response.payment_reference);
                // setShowDestination(true);    
                setLoading(false);
            }

        }).catch((error) => {
            // TODO: Handle Error
            console.log(error)
            // alert('Sorry, something went wrong!');
            setLoading(false);
        });

    }

    const getCostsOfJob = (jobId) => {
        const costsRequest = axiosCtx.instance.get(`cost-invoice/job/${jobId}`);
        const jobRequest = axiosCtx.instance.get(`job/${jobId}`);

        Promise.all([costsRequest, jobRequest]).then(([costsResponse, jobsResponse]) => {

            if (costsResponse.status === 200) {
                setCosts(() => costsResponse.data.data === undefined ? [] : costsResponse.data.data);
            }

            if (jobsResponse.status === 200) {
                let response = jobsResponse.data.data[0];
                setSelectedParty(response.party);
                // setSelectedJob(response);
                setSelectedProject(response.project);
            }

        }).catch((error) => {
            // TODO: Handle Error
            alert('Sorry, something went wrong!');
        });
    };

    const findMultipleAirwaybills = (jobId) => {
        const destination = axiosCtx.instance.get(`cost/find-multiple-airwaybills/${jobId}`);

        Promise.all([destination]).then(([destinationResponse]) => {
            if (destinationResponse.status === 200) {
                setPorts(destinationResponse.data.data);
                setShowDestination(true);
            }

        }).catch((error) => {
            // TODO: Handle Error
        });
    };

    const onInvoiceSubmit = () => {
        setFormError(null);
        setLoading(true);
        // Call login API
        const params = {
            job_id: (selectedJob) ? selectedJob.id : '',
            project_id: (selectedProject) ? selectedProject.id : '',
            party_id: (selectedParty) ? selectedParty.id : '',
            invoice_date: invoiceDate,
            currency_id: (invoiceCurrency) ? invoiceCurrency.id : '',
            destination: (destination) ? destination.id : '',
            our_reference: ourReference,
            received_amount: receivedAmount,
            payment_reference: paymentReference,
            costs_fields: JSON.stringify(costs),
            amount: parseFloat(totalAmountAfterVat).toFixed(2),
        }

        if (invoiceId) {
            axiosCtx.instance.put(`invoice/` + invoiceId, params)
                .then((response) => {
                    setLoading(false);
                    history.push({
                        pathname: `/Invoice`,
                        state: {}
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    setFormError(error.response.data);

                });
        }

    }

    const [jobtypes, setJobtypes] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [parties, setParties] = useState([]);
    const [projects, setProjects] = useState([]);
    const [currencys, setCurrency] = useState([]);
    const [costs, setCosts] = useState(null);
    const [showDestination, setShowDestination] = useState(false);
    const [ports, setPorts] = useState([]);
    const [destination, setDestination] = useState([]);
    const [ourReference, setOurReference] = useState('');
    const [statuses, setStatuses] = useState([]);

    const [selectedParty, setSelectedParty] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedJob, setSelectedJob] = useState('');
    const [invoiceId, setInvoiceId] = useState('');

    const [receivedAmount, setReceivedAmount] = useState('');
    const [paymentReference, setPaymentReference] = useState('');

    const [conversionRate, setConversionRate] = useState([]);

    const [invoiceDate, setInvoiceDate] = React.useState('');
    const [invoiceCurrency, setInvoiceCurrency] = React.useState('');
    const dateHandleChange = (event) => { setInvoiceDate(event.target.value); };
    const ourReferenceHandleChange = (event) => {
        setOurReference(event.target.value);
    };
    const receivedAmountHandleChange = (event) => {
        setReceivedAmount(event.target.value);
    };
    const paymentReferenceHandleChange = (event) => {
        setPaymentReference(event.target.value);
    };

    const getStatues = () => {
        axiosCtx.instance.get(`status`)
            .then((response) => {
                setStatuses(response.data.data);
            })
            .catch((error) => {
            });
    };

    const getJobtypes = () => {
        axiosCtx.instance.get(`job-type`)
            .then((response) => {
                setJobtypes(response.data.data);
            })
            .catch((error) => {
            });
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoices.length) : 0;


    const getJobs = () => {
        axiosCtx.instance.get(`job`)
            .then((response) => {
                setJobs(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getParties = () => {
        axiosCtx.instance.get(`party`)
            .then((response) => {
                setParties(response.data.data);
            })
            .catch((error) => {
            });
    };

    const getProjects = () => {
        axiosCtx.instance.get(`project`)
            .then((response) => {
                setProjects(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getCurrency = () => {
        axiosCtx.instance.get(`currency`)
            .then((response) => {
                setCurrency(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getCurrencyConvertion = (currency_label) => {
        setLoading(true);
        axiosCtx.instance.get(`get-by-currency/` + currency_label)
            .then((response) => {
                setConversionRate(JSON.parse(response.data.data));
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
            });
    };

    let totalAmountBeforeVat = 0;
    let totalVatAmount = 0;
    let totalAmountAfterVat = 0;

    let con_revenue_amount = 0;
    let con_revenue_total = 0;

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#2f67c9");

    return (
        <>
            <div className="content">
                {loading && loading == true ?
                    <div className="custom-loader">
                        <FadeLoader color={color} loading={loading} css={override} size={150} />
                    </div>
                    : ''
                }
                <Card >



                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Edit Invoice</Accordion.Header>
                            <Accordion.Body>
                                <Row className="mb-4">
                                    <Col md="6" className="my-4">
                                        <Dropdown
                                            id="parties"
                                            placeholder="Party"
                                            label="name"
                                            value={selectedParty}
                                            options={parties}
                                            onChange={(event, newValue) => {
                                                setSelectedParty(newValue);
                                            }}
                                            formError={(formError && formError.errors.party_id) ? formError.errors.party_id : ''}
                                        />
                                    </Col>
                                    <Col md="6" className="my-4">

                                        <FormControl variant="outlined" className="w-100">
                                            <Dropdown
                                                disabled={true}
                                                id="job"
                                                placeholder="Job number"
                                                label="job_id"
                                                options={jobs}
                                                value={selectedJob}
                                                onChange={(event, newValue) => {
                                                    setShowDestination(false);
                                                    getCostsOfJob(newValue.id);
                                                    findMultipleAirwaybills(newValue.id);
                                                    setSelectedJob(newValue);
                                                    setSelectedParty('');
                                                    setSelectedProject('');
                                                }}
                                                formError={(formError && formError.errors.job_id) ? formError.errors.job_id : ''}
                                            />
                                        </FormControl>

                                    </Col>
                                    <Col md="6" className="my-4">
                                        <Dropdown
                                            id="project"
                                            placeholder="Project"
                                            label="name"
                                            value={selectedProject}
                                            options={projects}
                                            onChange={(event, newValue) => {
                                                setSelectedProject(newValue);
                                            }}
                                            formError={(formError && formError.errors.project_id) ? formError.errors.project_id : ''}
                                        />
                                    </Col>
                                    <Col md="6" className="my-4">
                                        <Dropdown
                                            id="currency"
                                            placeholder="Invoice Currency"
                                            label="currency_label"
                                            value={invoiceCurrency}
                                            options={currencys}
                                            onChange={(event, newValue) => {
                                                setInvoiceCurrency(newValue);
                                                getCurrencyConvertion(newValue.currency_label);
                                            }}
                                            formError={(formError && formError.errors.currency_id) ? formError.errors.currency_id : ''}
                                        />
                                    </Col>
                                    <Col md="6" className="my-4 form-group">
                                        <InputDate
                                            id="date"
                                            placeholder="Dates"
                                            label="Dates"
                                            value={invoiceDate}
                                            onChange={dateHandleChange}
                                            formError={(formError && formError.errors.invoice_date) ? formError.errors.invoice_date : ''}
                                        />
                                    </Col>
                                    <Col md="6" className="my-4 form-group">
                                        <InputField
                                            id="our-reference"
                                            placeholder="Our Referencesss"
                                            label="Our Reference"
                                            value={ourReference}
                                            onChange={ourReferenceHandleChange}

                                        />
                                    </Col>
                                    <Col md="6" className="my-4 form-group">
                                        <InputField
                                            id="received-amount"
                                            type="number"
                                            placeholder="Received Amount"
                                            label="Received Amount"
                                            value={receivedAmount}
                                            onChange={receivedAmountHandleChange}

                                        />
                                    </Col>
                                    <Col md="6" className="my-4 form-group">
                                        <InputField
                                            id="payment-reference"
                                            placeholder="Payment Reference"
                                            label="Payment Reference"
                                            value={paymentReference}
                                            onChange={paymentReferenceHandleChange}

                                        />
                                    </Col>
                                    {showDestination ? <Col md="6" className="my-4 form-group">
                                        <Dropdown
                                            id="ports"
                                            placeholder="Destination"
                                            label="code"
                                            value={destination}
                                            options={ports}
                                            onChange={(event, newValue) => {
                                                setDestination(newValue);
                                            }}
                                        />
                                        <p className="text-danger small pt-2">
                                            Found multiple airwaybills so choose Destination
                                        </p>
                                    </Col> : ''}
                                </Row>
                                {
                                    costs !== null && !costs.length && (
                                        <h6 className="text-danger">Sorry, No Costs found for selected Job!</h6>
                                    )
                                }
                                {
                                    costs !== null && costs.length !== 0 && (
                                        <Table responsive>
                                            <thead >
                                                <tr>
                                                    <th>Revenue Description </th>
                                                    <th>Unit </th>
                                                    <th>Amount  </th>
                                                    <th>VAT%  </th>
                                                    <th>VAT Amount   </th>
                                                    <th className="text-right">Total  </th>
                                                    <th></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    costs.map((element, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td colSpan="100%">
                                                                        <div className="bg-light font-weight-bold p-2 border">{element.order_no}</div>
                                                                    </td>
                                                                </tr>

                                                                {
                                                                    element.costs && element.costs.map((costDetail, i) => {

                                                                        if (costDetail.revenue_currency != null && costDetail.revenue_total != null && costDetail.revenue_total > 0) {

                                                                            if (conversionRate[costDetail.revenue_currency.currency_label]) {
                                                                                console.log(conversionRate[costDetail.revenue_currency.currency_label]);
                                                                                con_revenue_amount = costDetail.revenue_amount * conversionRate[costDetail.revenue_currency.currency_label];
                                                                                con_revenue_total = costDetail.revenue_total * conversionRate[costDetail.revenue_currency.currency_label];
                                                                            } else {

                                                                                con_revenue_amount = costDetail.revenue_amount;
                                                                                con_revenue_total = costDetail.revenue_total;

                                                                            }

                                                                            let amount = parseFloat(con_revenue_amount).toFixed(2);
                                                                            let totalAmount = parseFloat(con_revenue_total).toFixed(2);
                                                                            let vatAmount = amount * (costDetail.revenue_vat / 100);
                                                                            let vatPercentage = costDetail.revenue_vat;

                                                                            totalAmountBeforeVat = (parseFloat(totalAmountBeforeVat) + parseFloat(amount));
                                                                            totalAmountAfterVat = (parseFloat(totalAmountAfterVat) + parseFloat(totalAmount));
                                                                            totalVatAmount = (parseFloat(totalVatAmount) + parseFloat(vatAmount));

                                                                            // let amount = parseFloat(costDetail.revenue_amount).toFixed(2);
                                                                            // let totalAmount = parseFloat(costDetail.revenue_total).toFixed(2);
                                                                            // let vatAmount = amount * (costDetail.revenue_vat / 100);
                                                                            // let vatPercentage = costDetail.revenue_vat;

                                                                            // totalAmountBeforeVat = (parseFloat(totalAmountBeforeVat) + parseFloat(amount));
                                                                            // totalAmountAfterVat = (parseFloat(totalAmountAfterVat) + parseFloat(totalAmount));
                                                                            // totalVatAmount = (parseFloat(totalVatAmount) + parseFloat(vatAmount));

                                                                            // console.log(totalAmountBeforeVat, totalAmountAfterVat, totalVatAmount);

                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            {costDetail.cost_description.name}
                                                                                            {/* <input
                                                                                    className="form-control"
                                                                                    value=
                                                                                /> */}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            {costDetail.cost_unit}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            {amount}
                                                                                            {/* <input
                                                                                    className="form-control w-50"
                                                                                    value=
                                                                                /> */}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            {vatPercentage}
                                                                                            {/* <input
                                                                                    className="form-control w-50"
                                                                                    value=
                                                                                /> */}
                                                                                        </div>
                                                                                    </td>

                                                                                    <td>
                                                                                        {(vatAmount) ? Number(vatAmount).toFixed(2) : ''}
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {totalAmount}
                                                                                    </td>
                                                                                    <td width={150}></td>
                                                                                    {/* <td className="text-right"> <a onClick={() => onDeleteCost(index, i)} style={{ cursor: "pointer" }} className="font-weight-bold text-danger">Delete</a> </td> */}
                                                                                    {/* <td className="text-right">
                                                                                <button onClick={() => onCostEditPress(costDetail, element)} className="btn btn-primary btn-sm font-weight-bold mr-3">Edit</button>
                                                                                <button className="btn btn-danger btn-sm font-weight-bold ">Delete</button> 
                                                                            </td> */}
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    )
                                }
                                {
                                    costs !== null && costs.length >= 1 && (
                                        <Table responsive className="text-right table-borderless border-bottom border-top">
                                            <tbody>
                                                <tr>
                                                    <td > Total Amount before VAT</td>
                                                    <td width={150}> {parseFloat(totalAmountBeforeVat).toFixed(2)} </td>
                                                </tr>
                                                <tr>
                                                    <td>  VAT </td>
                                                    <td> {parseFloat(totalVatAmount).toFixed(2)} </td>
                                                </tr>
                                                <tr>
                                                    <td> Total Amount  </td>
                                                    <td> {parseFloat(totalAmountAfterVat).toFixed(2)} </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    )
                                }
                                {formError &&
                                    formError.errors &&
                                    formError.errors.common && (
                                        <p className="text-danger small pt-2">
                                            {formError.errors.common}
                                        </p>
                                    )}


                            </Accordion.Body>
                        </Accordion.Item>

                        <button onClick={onInvoiceSubmit} className="btn btn-primary ml-3">Save</button>
                    </Accordion>
                </Card>
            </div>
        </>
    );
}

export default InvoiceEdit;
