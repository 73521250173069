import React from "react";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Stack from '@mui/material/Stack';

const InputField = props => {
    return (
        <FormControl variant="outlined" className="w-100">
            <Stack component="form" noValidate spacing={3}>
                <TextField
                    disabled={props.disabled}
                    size="small"
                    type={props.type}
                    id={props.id}
                    label={props.label}
                    value={props.value}
                    multiline={props.multiline}
                    rows={props.rows}
                    onChange={(e) => props.onChange(e)}
                    onBlur={(e) => props.onBlur(e)}
                />
            </Stack>
            {
                props.formError && (
                    <p className="text-danger small pt-2">
                        {props.formError.join('. ')}
                    </p>
                )}
        </FormControl>
    );
};

export default InputField;

