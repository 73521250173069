
import React, { useContext, useEffect } from "react";
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';

function RedirectRoot() {
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    else {
      history.replace('/job-list');
    }
  }, []);
  return (
    <></>
  );
}

export default RedirectRoot;
