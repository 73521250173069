import InvoiceEdit from 'components/InvoiceEdit';
import Wrapper from 'components/Wrapper';

function InvoiceEditPage() {
  return (
    <Wrapper>
      <InvoiceEdit />
    </Wrapper>
  );
}

export default InvoiceEditPage;
