import axios from 'axios';

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/api",
  baseURL: "https://billing.cordiacesolutions.com/backend/public/api",
  headers: { Accept: 'application/json' }, 
});


export default instance;
