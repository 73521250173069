import Customer from 'components/Customer';
import Wrapper from 'components/Wrapper';

function CustomerPage() {
  return (
    <Wrapper>
      <Customer />
    </Wrapper>
  );
}

export default CustomerPage;
