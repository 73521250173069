import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AuthContextProvider } from './store/auth-context';

import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";


ReactDOM.render(
  <AuthContextProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" render={(props) => <AdminLayout {...props} />} />

        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect to="/auth/Login" />
      </Switch>
    </BrowserRouter>
  </AuthContextProvider>,
  document.getElementById("root")
);
