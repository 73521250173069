// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
// reactstrap components
import { Card } from "reactstrap";
import AxiosContext from '../store/axios-context';
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Job Code',
  },
  {
    id: 'jobType',
    numeric: false,
    disablePadding: false,
    label: 'job Type',
  },
  {
    id: 'project',
    numeric: false,
    disablePadding: false,
    label: 'project',
  },
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'party',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function MasterData() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const history = useHistory();

  const [jobs, setJob] = useState([]);
  const [projects, setProjects] = useState([]);
  const [jobtypes, setJobtypes] = useState([]);
  const [parties, setParties] = useState([]);
  const [ports, setPorts] = useState([]);
  const [costs, setCosts] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const getCurrencies = () => {
    axiosCtx.instance.get(`currency`)
      .then((response) => {
        setCurrencies(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };



  const getPorts = () => {
    axiosCtx.instance.get(`port`)
      .then((response) => {
        setPorts(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getStatues = () => {
    axiosCtx.instance.get(`status`)
      .then((response) => {
        setStatuses(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getSubtypes = () => {
    axiosCtx.instance.get(`sub-type`)
      .then((response) => {

        setSubtypes(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getJobs = () => {
    axiosCtx.instance.get(`job`)
      .then((response) => {
        setJob(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getProjects = () => {
    axiosCtx.instance.get(`project`)
      .then((response) => {
        setProjects(response.data.data);

      })
      .catch((error) => {
      });
  };

  const getJobtypes = () => {
    axiosCtx.instance.get(`job-type`)
      .then((response) => {
        setJobtypes(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getParties = () => {
    axiosCtx.instance.get(`party`)
      .then((response) => {
        setParties(response.data.data);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    getStatues();
    getSubtypes();
    getPorts();
    getJobs();
    getJobtypes();
    getParties();
    getProjects();
    getCurrencies();
  }, []);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = projects.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();


    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showFilter, setShowFilter] = useState(false);


  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projects.length) : 0;
  const [age, setAge] = React.useState('');
  const handleChange = (event) => { setAge(event.target.value); };
  return (
    <div className="content">
      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Project List</h4>
      </div>
      <Card >
      <Table responsive className="custom-table border-bottom">
        <thead>

        </thead>
        <tbody>
          <tr>
            <th></th>
            <th>Id </th>
            <th>Name </th>
            <th>Sub Type </th>
          </tr>
          {projects.map((element, index) => (
            <tr>
              <td></td>
              <td>{element.id}</td>
              <td>{element.name}</td>
              <td>{(element.sub_type_id == 1) ? 'SEA' : (element.sub_type_id == 2) ? 'AIR' : 'LAND' }</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Card>

      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Sub Type List</h4>
      </div>
      <Card >
      <Table responsive className="custom-table border-bottom">
        <thead>

        </thead>
        <tbody>
          <tr>
          <th></th>
            <th>Id </th>
            <th>Sub Type </th>
          </tr>
          {subtypes.map((element, index) => (
            <tr>
              <td></td>
              <td>{element.id}</td>
              <td>{element.sub_type}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Card >

      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Port List</h4>
      </div>
      <Card >
      <Table responsive className="custom-table border-bottom">
        <thead>

        </thead>
        <tbody>
          <tr>
          <th></th>
            <th>Id </th>
            <th>Code </th>
            <th>Type </th>
            <th>Name </th>
          </tr>
          {ports.map((element, index) => (
            <tr>
              <td></td>
              <td>{element.id}</td>
              <td>{element.code}</td>
              <td>{(element.sub_type_id == 1) ? 'SEA' : 'AIR' }</td>
              <td>{element.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Card >

      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Job Type List</h4>
      </div>
      <Card >
      <Table responsive className="custom-table border-bottom">
        <thead>

        </thead>
        <tbody>
          <tr>
          <th></th>
            <th>Id </th>
            <th>Type </th>
          </tr>
          {jobtypes.map((element, index) => (
            <tr>
              <td></td>
              <td>{element.id}</td>
              <td>{element.type}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Card >

      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Parties List</h4>
      </div>
      <Card >
      <Table responsive className="custom-table border-bottom">
        <thead>

        </thead>
        <tbody>
          <tr>
          <th></th>
            <th>Id </th>
            <th>Code </th>
            <th>Name </th>
            <th>Address 1 </th>
            <th>City </th>
            <th>Zip code </th>
            <th>Email </th>
            <th>Mobile </th>
          </tr>
          {parties.map((element, index) => (
            <tr>
              <td></td>
              <td>{element.id}</td>
              <td>{element.code}</td>
              <td>{element.name}</td>
              <td>{element.address_1}</td>
              <td>{element.city}</td>
              <td>{element.zip_code}</td>
              <td>{element.email}</td>
              <td>{element.mobile_no}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Card >

      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Status List</h4>
      </div>
      <Card >
      <Table responsive className="custom-table border-bottom">
        <thead>

        </thead>
        <tbody>
          <tr>
            <th></th>
            <th>Id </th>
            <th>Name </th>
          </tr>
          {statuses.map((element, index) => (
            <tr>
              <td></td>
              <td>{element.id}</td>
              <td>{element.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Card >

      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Currency List</h4>
      </div>
      <Card >
      <Table responsive className="custom-table border-bottom">
        <thead>

        </thead>
        <tbody>
          <tr>
            <th></th>
            <th>Id </th>
            <th>Country</th>
            <th>Currency </th>
          </tr>
          {currencies.map((element, index) => (
            <tr>
              <td></td>
              <td>{element.id}</td>
              <td>{element.country_name}</td>
              <td>{element.currency_label}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Card >
    </div>
  );
}
